import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/app/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {    
    auth: {
      clientId:environment.clientId, 
      authority: "https://login.microsoftonline.com/"+environment.tenentId, 
      redirectUri: '/',
      postLogoutRedirectUri: '/',      
      //clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, 
        storeAuthStateInCookie: isIE, 
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}
export const protectedResources = {
    apiDBot: {
        endpoint:environment.APIBaseUrl, 
        scopes: {
            write: [environment.APIScopeURL]
        }
    },
    uploadDocument: {
        endpoint: environment.uploadDocumentURL,
        scopes: {
            write: [environment.APIScopeURL]
        }
    },
    resetUploadedDocument: {
        endpoint: environment.refreshDocumentURL,
        scopes: {
            read: [environment.APIScopeURL]
        }
    },
    processUploadedDocument: {
        endpoint: environment.processDocumentURL,
        scopes: {
            read: [environment.APIScopeURL]
        }
    },
    searchSummaryDocument : {
        endpoint: environment.summaryDocumentURL,
        scopes: {
            write: [environment.APIScopeURL]
        }
    },
    conversationHistory : {
        endpoint: environment.summaryDocumentURL,
        scopes: {
            write: [environment.APIScopeURL]
        }
    },
    orange : {
        endpoint: environment.orangeProjectURL,
        scopes:{
            write: [environment.APIScopeURL]
        }
    },
    verifyuser : {
        endpoint: environment.verifyUserURL,
        scopes:{
            write: [environment.APIScopeURL] 
        }
    }
    
}

export const loginRequest = {
    scopes: []
};