<div class="container">
  <!-- <div class="header">
     <div class="GenDLogo">
        <img class="GenDLogoImage" src="../assets/images/logo_v2.png" alt="Gen-D Logo" />
        <span class="GenDHeading">Gen-D</span>
     </div>
  </div> -->
  <!-- <div class="main-content"> -->
     <div class="error-message">
        <h1>{{ lblAccess }}</h1>
     </div>
  <!-- </div> -->
</div>