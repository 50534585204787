import { Component, Input, ElementRef, HostListener, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CheckboxOptions, CheckboxGroupOptions } from '@usitsdasdesign/dds-ng/checkbox';
import { Themes, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { FeedbackService } from '../Services/feedback.service';
import { HttpClient } from '@angular/common/http';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from '../app.component';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-feedback-dis-like',
  templateUrl: './feedback-dis-like.component.html',
  styleUrls: ['./feedback-dis-like.component.css']
})
export class FeedbackDisLikeComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy = new Subject<void>();
  private modalInitialized = false;
  feedbackRes: any = "";
  updateCharacterCount() { }
  
  constructor(
    private modalService: ModalService,
    private apiService: FeedbackService, 
    private http: HttpClient, 
    private i18n: I18nService, 
    public appcomponentService: AppComponentService,
    private elementRef: ElementRef
  ) { }

  @Input() isFooter: boolean;
  @Input() size: string;
  @Input() isInverse: boolean;

  lblFeedback: string;
  lblGoodResponse: string;
  lblCheckbox1: string;
  lblCheckbox2: string;
  lblCheckbox3: string;
  lblCheckbox4: string;
  lblCheckbox5: string;
  lblAdditionalComment: string;
  lblSubmit: string;
  lstSelectedOptions: any = [];
  txtFeedback: any = "";
  feedbackDisLike: boolean = false;
  checkboxState1: boolean = false;
  checkboxState2: boolean = false;
  checkboxState3: boolean = false;
  checkboxState4: boolean = false;
  checkboxState5: boolean = false;

  groupOptions: CheckboxGroupOptions = {
    title: 'Title',
    isRequired: false,
    isInverse: false,
  };
  @Input() lstoptionDislike: any;
  @Input() lstDislikeOptions: any;

  // Add click handler to detect clicks outside the modal
  @HostListener('document:mousedown', ['$event'])
  clickOutside(event: MouseEvent) {
    // Only process if modal is fully initialized and event target exists
    if (this.modalInitialized && event.target) {
      // Check if click is outside our component
      const clickedInside = this.elementRef.nativeElement.contains(event.target);
      
      if (!clickedInside) {
        // User clicked outside the modal, close it
        this.close();
      }
    }
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblFeedback = "Commentaires";
            this.lblGoodResponse = "Pourquoi cette réponse n'était-elle pas bonne?";
            this.lblCheckbox1 = "Hallucination de l'IA : des informations fausses ont été fournies";
            this.lblCheckbox2 = "L'IA n'a pas compris correctement le contexte fourni";
            this.lblCheckbox3 = "L'IA a dévié de mes instructions";
            this.lblCheckbox4 = "Réponse de l'IA trop générique / non pertinente par rapport à la requête";
            this.lblCheckbox5 = "Autre (veuillez préciser dans le commentaire ci-dessous)";
            this.lblAdditionalComment = "Commentaire supplémentaire";
            this.lblSubmit = "Soumettre";
            break;
          case "ES":
            this.lblFeedback = "Comentarios";
            this.lblGoodResponse = "¿Por qué no fue una buena respuesta?";
            this.lblCheckbox1 = "Alucinación de IA: se proporcionó información falsa";
            this.lblCheckbox2 = "La IA no entendió correctamente el contexto proporcionado";
            this.lblCheckbox3 = "La IA se desvió de mis instrucciones";
            this.lblCheckbox4 = "Respuesta de la IA demasiado genérica / irrelevante para la consulta";
            this.lblCheckbox5 = "Otro (por favor especifica en el comentario a continuación)";
            this.lblAdditionalComment = "Comentario Adicional";
            this.lblSubmit = "Enviar";
            break;
          default:
            this.lblFeedback = "Feedback";
            this.lblGoodResponse = "Why was this not a good response?";
            this.lblCheckbox1 = "AI hallucination: false information was provided";
            this.lblCheckbox2 = "AI did not understand the provided context correctly";
            this.lblCheckbox3 = "AI deviated from my instructions";
            this.lblCheckbox4 = "AI response too generic / irrelevant to the query";
            this.lblCheckbox5 = "Other (please specify in the comment below)";
            this.lblAdditionalComment = "Additional Comment";
            this.lblSubmit = "Submit";
            break;
        }
      });
  }

  ngOnInit() {
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) {
      this.appcomponentService.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentService.setIsPinChecked(true);
      }
      else {
        this.appcomponentService.setIsPinChecked(false);
      }
    }
    this.manageLocale();
  }
  
  ngAfterViewInit() {
    // Delay setting the initialization flag to avoid the modal 
    // closing immediately after opening
    setTimeout(() => {
      this.modalInitialized = true;
    }, 300);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  options: CheckboxOptions = {
    label: 'Label',
    theme: Themes.green,
    isIndeterminate: false,
    disabled: false,
    isError: false,
    isRequired: false,
    errorState: ErrorState.default,
    ariaLabel: '',
    customClass: ''
  };

  stateChanged(state: boolean, item: string) {
    if (state) {
      if (!this.lstSelectedOptions.includes(item)) {
        this.lstSelectedOptions.push(item);
      }
    } else {
      this.lstSelectedOptions = this.lstSelectedOptions.filter(x => x !== item);
    }
    console.log(this.lstSelectedOptions);
  }

  close() {
    var model = {
      "feedback": "negative",
      "feedbackType": "negative",
      "document_Name": this.lstoptionDislike?.documentNames || [],
      "userQuery": this.lstoptionDislike?.userQuery,
      "apiResponse": this.lstoptionDislike?.apiResponse,
      "gptModel": this.lstoptionDislike?.gptModel,
      "conversationId": this.lstoptionDislike?.convid,
      "selectedOption": this.lstSelectedOptions
    }
    var Botmodel = {
      "selectedOption": this.lstSelectedOptions,
      "apiResponse": this.lstoptionDislike?.apiResponse,
      "userQuery": this.lstoptionDislike?.userQuery,
      "feedback": "negative",
      "feedbackType": "negative",
      "botAppName": this.lstoptionDislike?.botAppName
    }
    
    if (this.lstoptionDislike?.isBotActive) {
      console.log("submitting EFS Feedback model", Botmodel);
      this.apiService.submitBotFeedbackData(Botmodel)
        .subscribe(res => {
          console.log("EFS Feedback response", res);
          this.modalService.close();
        },
        (err) => {
          console.log("EFS Feedback error", err);
          console.log("Full error response:", JSON.stringify(err, null, 2));
          console.log("Error details:", err.error);
          this.modalService.close();
        });
    } else {
      this.apiService.submitFeedbackData(model)
        .subscribe(res => {
          console.log("Feedback response", res);
          this.modalService.close();
        },
        (err) => {
          this.modalService.close();
        });
      this.feedbackDisLike = true;
      console.log("dislike res", model);
    }
  }

  Submit() {
    console.log("lstoptiondislike:", this.lstoptionDislike)
    var feedRes = (this.txtFeedback == null) || (this.txtFeedback.trim() == "") ? 'negative' : this.txtFeedback;
    var userQuery = this.lstoptionDislike?.userQuery;
    if (!userQuery || userQuery.trim() === '') {
      userQuery = "summary";
    }
    var model = {
      "feedback": feedRes.replace(/(\r\n|\n|\r)/gm, ""),
      "feedbackType": "negative",
      "document_Name": this.lstoptionDislike?.document_Name || [],
      "userQuery": userQuery,
      "apiResponse": this.lstoptionDislike?.apiResponse,
      "gptModel": this.lstoptionDislike?.gptModel,
      "conversationId": this.lstoptionDislike?.convid,
      "selectedOption": this.lstSelectedOptions
    }
    var EFSmodel = {
      "selectedOption": this.lstSelectedOptions,
      "apiResponse": this.lstoptionDislike?.apiResponse,
      "userQuery": this.lstoptionDislike?.userQuery,
      "feedback": feedRes.replace(/(\r\n|\n|\r)/gm, ""),
      "feedbackType": "negative",
      "botAppName": this.lstoptionDislike?.botAppName
    }
    
    if (this.lstoptionDislike?.isDocAnalyzerActive) {
      this.apiService.submitDocFeedbackData(model)
        .subscribe(res => {
          console.log("Doc Analyzer Feedback response", res);
          this.modalService.close();
        },
        (err) => {
          console.log(" error Doc Analyzer Feedback response", err);
          this.modalService.close();
        });
    } else if (this.lstoptionDislike?.isBotActive) {
      this.apiService.submitBotFeedbackData(EFSmodel)
        .subscribe(res => {
          console.log("EFS Feedback response", res);
          this.modalService.close();
        },
        (err) => {
          console.log(" error EFS Feedback response", err);
          this.modalService.close();
        });
    } else {
      this.apiService.submitFeedbackData(model)
        .subscribe(res => {
          console.log("Feedback response", res);
          this.modalService.close();
        },
        (err) => {
          this.modalService.close();
        });
      this.feedbackDisLike = true;
    }
  }
}