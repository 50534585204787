import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';
import { DocumentAnalyzerComponent } from './document-analyzer/document-analyzer.component';
import { AgentCentralComponent } from './agent-central/agent-central.component';
import { EfsComponent } from './efs/efs.component';
import { DeloitteVoiceComponent } from './deloitte-voice/deloitte-voice.component';
import { DeloitteVoiceCreateComponent } from './deloitte-voice/deloitte-voice-create/deloitte-voice-create.component';
import { DeloitteVoiceFlowComponent } from './deloitte-voice/deloitte-voice-flow/deloitte-voice-flow.component';
import { DeloitteVoiceReviseComponent } from './deloitte-voice/deloitte-voice-revise/deloitte-voice-revise.component';
import { ErrorComponent } from './error/error.component';
import { BillingComponent } from './billing/billing.component';
import { CrmComponent } from './crm/crm.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'document-analyzer', component: DocumentAnalyzerComponent, canActivate: [MsalGuard] },
  { path: 'agent-central', component: AgentCentralComponent, canActivate: [MsalGuard] },
  { path: 'efs', component: EfsComponent, canActivate: [MsalGuard] },
  { path: 'error', component: ErrorComponent, canActivate: [MsalGuard] },
  {path: '', component:HomeComponent,canActivate: [MsalGuard]  }, 
  {
    path: 'deloitte-voice',
    component: DeloitteVoiceComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', component: DeloitteVoiceFlowComponent },
      { path: 'create', component: DeloitteVoiceCreateComponent },
      { path: 'revise', component: DeloitteVoiceReviseComponent },
      { path: '**', redirectTo: '' }
    ]
  },
  { path:"billing",component: BillingComponent, canActivate: [MsalGuard]},
  { path:"crm",component: CrmComponent, canActivate: [MsalGuard]},
  { path: "**", redirectTo: '' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }