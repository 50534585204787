import { Component, Input, Output, EventEmitter } from '@angular/core';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-deloitte-voice-error',
  templateUrl: './deloitte-voice-error.component.html',
  styleUrls: ['./deloitte-voice-error.component.css'],
  standalone: false
})
export class DeloitteVoiceErrorComponent {
  lblErrorHeader!: string;
  lblErrorDetailsPart1!: string;
  lblErorrDetailsTeamsChannel!: string;
  lblErrorDetailsPart2!: string;
  lblErrorDetailsHere!: string;
  lblErrorDetailsPart3!: string;
  lblGoBack!: string;
  private destroy = new Subject<void>();

  teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  serviceNowLink: string = "https://deloittecan.service-now.com/mysupport?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9&topic=null"
  


  @Output() goBackEvent = new EventEmitter<void>();

  constructor(
    private i18n: I18nService
  ) {
   
  }

  goBack(): void {
    this.goBackEvent.emit(); 
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblErrorHeader = "Oups ! Quelque chose a mal tourné.";
            this.lblErrorDetailsPart1 = "Veuillez réessayer en cliquant sur le bouton « Retour ». Si le problème persiste, rendez-vous sur la ";
            this.lblErorrDetailsTeamsChannel = "communauté des utilisateurs Gen-D";
            this.lblErrorDetailsPart2 = " pour signaler le problème et soumettre un rapport de bug ";
            this.lblErrorDetailsPart3 = ". Notre équipe s'en occupera dès que possible.";
            this.lblGoBack = "Retour";
            break;
          case "ES":
            this.lblErrorHeader = "¡Ups! Algo salió mal.";
            this.lblErrorDetailsPart1 = "Por favor, intenta de nuevo haciendo clic en el botón 'Regresar'. Si el problema persiste, visita la";
            this.lblErorrDetailsTeamsChannel = "Comunidad de Usuarios de Gen-D";
            this.lblErrorDetailsPart2 = " para publicar sobre el problema y enviar un informe de error ";
            this.lblErrorDetailsPart3 = ". Nuestro equipo lo abordará lo antes posible.";
            this.lblGoBack = "Regresar";
            break;
          default:
            this.lblErrorHeader = "Oops! Something went wrong.";
            this.lblErrorDetailsPart1 = "Please try again by clicking the 'Go Back' button. If the issue persists, visit the ";
            this.lblErorrDetailsTeamsChannel = "Gen-D User Community";
            this.lblErrorDetailsPart2 = " to post about the problem and submit a bug report ";
            this.lblErrorDetailsPart3 = ". Our team will address it as soon as possible.";
            this.lblGoBack = "Go Back";
            break;
        }
      });
  }

  ngOnInit(): void {
    this.manageLocale();
  }

}
