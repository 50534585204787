import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef, OnDestroy, input, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { BotResponseService } from '../Services/bot-response.service';
import * as docx from 'docx-preview';

import JSZip from 'jszip';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { AppComponentService } from '../Services/appcomponentservice';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';

@Component({
  selector: 'app-file-preview-popup',
  templateUrl: './file-preview-popup.component.html',
  styleUrls: ['./file-preview-popup.component.css']
})
export class FilePreviewPopupComponent implements OnInit, OnDestroy {
  @Input() fileSource: Blob;
  @ViewChild('docxContainer', {static: false}) docxContainer: ElementRef;
  @Output() closeEvent = new EventEmitter<void>();
  @Input() fileName: string = 'document.docx';
  @Input() botAppName: string;
  isLoading = false;
  error: string | null = null;
  documentContent: string = '';
  documentLoaded = false;
  private destroy = new Subject<void>();
  lblFilePreview: string;
  lblDownload: string;
  selectedLanguage: string = 'EN';
  lang1: string;
  lblLoadingDoc: string;
  // Flag to prevent immediate closing on modal open
  private modalJustOpened = false;

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private botresponseservice: BotResponseService,
    private cdr: ChangeDetectorRef,
    public appcomponentservice: AppComponentService,
    private i18n: I18nService,
    private elementRef: ElementRef
  ) {}

  // Listen for global click events - more direct approach
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Skip if the modal just opened to prevent immediate closing
    if (this.modalJustOpened) {
      this.modalJustOpened = false;
      return;
    }

    // Check if the click target is part of this component
    if (!this.elementRef.nativeElement.contains(event.target as Node)) {
      this.closeModal();
    }
  }

  // Listen for escape key press
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeModal();
  }

  manageLocale(){
    this.i18n
    .getLocaleObs()
    .pipe(takeUntil(this.destroy))
    .subscribe((locale) => {
      switch (locale) {
        case "FR":
          this.lang1 = 'fr';
          this.lblFilePreview = "Aperçu du fichier";
          this.lblDownload = "Télécharger";
          this.lblLoadingDoc = "Chargement du document...";
        break;
        case "ES":
          this.lang1 = 'es';
          this.lblFilePreview = "Vista previa del archivo";
          this.lblDownload = "Descargar";
          this.lblLoadingDoc = "Cargando documento...";
        break;
      default:
        this.lang1 = 'en';
        this.lblFilePreview = "File Preview";
        this.lblDownload = "Download";
        this.lblLoadingDoc = "Loading document...";
        break;
      }
    });
  }
  

  ngOnInit() {
    this.manageLocale();
    this.cdr.detectChanges(); // Force update to show loading spinner
    
    if (this.fileSource) {
      this.loadDocument();
    }
    
    // Set flag to prevent immediate closing
    this.modalJustOpened = true;
    
    // Reset flag after a short delay
    setTimeout(() => {
      this.modalJustOpened = false;
    }, 300);
    
    //centering chat settings modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
  }
  
  async loadDocument() {
    try {
      this.isLoading = true; // Set loading to true immediately when component initializes

      this.error = null;
      
      const response = await this.botresponseservice.BotPreviewDocument(this.fileSource, this.botAppName).toPromise();
      
      if (response instanceof Blob) {
        const arrayBuffer = await response.arrayBuffer();
        await this.renderDocxContent(arrayBuffer);
      } else {
        this.error = 'Invalid response format';
      }
    } catch (error) {
      console.error('Error loading document:', error);
      this.error = 'Error loading document';
    } finally {
      this.isLoading = false; // Set loading to false only after document is rendered
      this.cdr.detectChanges(); // Force update to hide spinner and show document
    }
  }

  ngAfterViewInit() {
    if (this.docxContainer) {
      this.setupDocumentContainer();
    }
  }



  
  private async renderDocxContent(arrayBuffer: ArrayBuffer) {
    try {
      // First approach: Use docx library for full formatting including images
      const options = {
        debug: true,
        className: 'docx',
        inWrapper: true,
        ignoreHeight: true,
        ignoreWidth: true,
        ignoreFonts: true,
        ignoreLastRenderedPageBreak: true,
        breakPages: true,
        useBase64URL: true, // Important for images
        experimental: true,
        trimXmlDeclaration: true,
        useMathMLPolyfill: true,
        renderHeaders: true,
        renderFooters: true,
        renderFootnotes: true
      };
      
      // Clear previous content but keep the buttons at the top
      if (this.docxContainer) {
        // We only want to clear the document content, not the button container
        const documentContentEl = this.docxContainer.nativeElement.querySelector('.document-content');
        if (documentContentEl) {
          documentContentEl.innerHTML = '';
        } else {
          // If the structure doesn't exist yet, create it
          this.setupDocumentContainer();
        }
        
        // Get reference to the document content container
        const contentContainer = this.docxContainer.nativeElement.querySelector('.document-content');
        await docx.renderAsync(arrayBuffer, contentContainer);
      } else {
        console.error('DOCX container not found');
        // Fallback to basic text extraction if the container isn't available
        await this.extractDocxContentWithFormatting(arrayBuffer);
      }
      
      // Extract text for other purposes (like placeholder detection)
      const textContent = await this.extractTextFromDocx(arrayBuffer);
      // Do something with the text content if needed
      console.log('Extracted plain text:', textContent);
    } catch (error) {
      console.error('Error rendering document:', error);
      this.error = 'Failed to render document content';
      // Fallback to basic extraction if rendering fails
      await this.extractDocxContentWithFormatting(arrayBuffer);
    }
  }
  
  private setupDocumentContainer() {
    if (!this.docxContainer) return;

    this.docxContainer.nativeElement.innerHTML = `
      <div class="document-viewer">
        
        <div class="document-content"></div>
      </div>
    `;
    
    // Add event listeners to buttons
    const downloadBtn = this.docxContainer.nativeElement.querySelector('.download-btn');
    const closeBtn = this.docxContainer.nativeElement.querySelector('.close-btn');
    
    if (downloadBtn) {
      downloadBtn.addEventListener('click', () => this.downloadFile());
    }
    
    if (closeBtn) {
      closeBtn.addEventListener('click', () => this.closeDocument());
    }
  }
  
  
  private closeDocument() {
    if (this.docxContainer) {
      this.docxContainer.nativeElement.style.display = 'none';
    }
  }
  
  private async extractDocxContentWithFormatting(arrayBuffer: ArrayBuffer) {
    try {
      // Make sure we have a proper document structure with buttons
      if (this.docxContainer) {
        const documentContentEl = this.docxContainer.nativeElement.querySelector('.document-content');
        if (!documentContentEl) {
          this.setupDocumentContainer();
        }
      }
      
      const zip = await JSZip.loadAsync(arrayBuffer);
      const documentXml = await zip.file('word/document.xml')?.async('text');
      
      if (documentXml) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(documentXml, 'text/xml');
        
        // Extract formatted content
        const formattedContent = await this.extractFormattedContent(zip, xmlDoc);
        
        // Insert content but preserve buttons
        if (this.docxContainer) {
          const contentContainer = this.docxContainer.nativeElement.querySelector('.document-content');
          if (contentContainer) {
            contentContainer.innerHTML = formattedContent;
          } else {
            this.documentContent = formattedContent;
          }
        } else {
          this.documentContent = formattedContent;
        }
        
        console.log('Extracted formatted content');
      } else {
        this.error = 'No content found in document';
      }
    } catch (error) {
      console.error('Error extracting content:', error);
      this.error = 'Failed to extract document content';
    }
  }
  
  private async extractFormattedContent(zip: JSZip, xmlDoc: Document): Promise<string> {
    // Process paragraphs and their formatting
    const paragraphs = xmlDoc.getElementsByTagName('w:p');
    let formattedContent = '';
    
    // Get relationships to handle images
    const relsXml = await zip.file('word/_rels/document.xml.rels')?.async('text');
    const relationships = relsXml ? this.parseRelationships(relsXml) : {};
    
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i];
      let paragraphContent = '';
      
      // Process runs (text with same formatting)
      const runs = paragraph.getElementsByTagName('w:r');
      for (let j = 0; j < runs.length; j++) {
        const run = runs[j];
        
        // Check for images
        const drawings = run.getElementsByTagName('w:drawing');
        if (drawings.length > 0) {
          for (let k = 0; k < drawings.length; k++) {
            // Extract image reference
            const blipElements = drawings[k].getElementsByTagName('a:blip');
            if (blipElements.length > 0) {
              const embedId = blipElements[0].getAttribute('r:embed');
              if (embedId && relationships[embedId]) {
                const imagePath = relationships[embedId].Target;
                // Extract and embed the image
                const imageSrc = await this.extractImageFromDocx(zip, imagePath);
                if (imageSrc) {
                  paragraphContent += `<img src="${imageSrc}" alt="Document image" />`;
                }
              }
            }
          }
          continue;
        }
        
        // Process text
        const textElements = run.getElementsByTagName('w:t');
        let runText = '';
        for (let k = 0; k < textElements.length; k++) {
          runText += textElements[k].textContent || '';
        }
        
        // Process formatting (bold, italic, etc.)
        const formatting = run.getElementsByTagName('w:rPr')[0];
        if (formatting && runText) {
          // Bold
          if (formatting.getElementsByTagName('w:b').length > 0) {
            runText = `<strong>${runText}</strong>`;
          }
          // Italic
          if (formatting.getElementsByTagName('w:i').length > 0) {
            runText = `<em>${runText}</em>`;
          }
          // Underline
          if (formatting.getElementsByTagName('w:u').length > 0) {
            runText = `<u>${runText}</u>`;
          }
          // Text highlight
          const highlight = formatting.getElementsByTagName('w:highlight')[0];
          if (highlight) {
            const color = highlight.getAttribute('w:val') || 'yellow';
            runText = `<span style="background-color:${color}">${runText}</span>`;
          }
          // Text color
          const color = formatting.getElementsByTagName('w:color')[0];
          if (color) {
            const colorVal = color.getAttribute('w:val') || 'auto';
            if (colorVal !== 'auto') {
              runText = `<span style="color:#${colorVal}">${runText}</span>`;
            }
          }
        }
        
        paragraphContent += runText;
      }
      
      // Check for paragraph styling (headers, lists, etc.)
      const pStyle = paragraph.getElementsByTagName('w:pStyle')[0];
      if (pStyle) {
        const styleId = pStyle.getAttribute('w:val');
        if (styleId && styleId.startsWith('Heading')) {
          const level = styleId.replace('Heading', '');
          formattedContent += `<h${level}>${paragraphContent}</h${level}>`;
        } else {
          formattedContent += `<p>${paragraphContent}</p>`;
        }
      } else {
        // Check if it's a list item
        if (paragraph.getElementsByTagName('w:numPr').length > 0) {
          formattedContent += `<li>${paragraphContent}</li>`;
        } else {
          formattedContent += `<p>${paragraphContent}</p>`;
        }
      }
    }
    
    // Clean up any list items to be within proper list tags
    formattedContent = formattedContent.replace(/<li>(.*?)<\/li>/g, '<ul><li>$1</li></ul>');
    formattedContent = formattedContent.replace(/<\/ul><ul>/g, '');
    
    return formattedContent;
  }
  
  private parseRelationships(relsXml: string): { [id: string]: { Target: string, Type: string } } {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(relsXml, 'text/xml');
    const relationships: { [id: string]: { Target: string, Type: string } } = {};
    
    const relElements = xmlDoc.getElementsByTagName('Relationship');
    for (let i = 0; i < relElements.length; i++) {
      const rel = relElements[i];
      const id = rel.getAttribute('Id');
      const target = rel.getAttribute('Target');
      const type = rel.getAttribute('Type');
      
      if (id && target) {
        relationships[id] = { Target: target, Type: type || '' };
      }
    }
    
    return relationships;
  }
  
  private async extractImageFromDocx(zip: JSZip, imagePath: string): Promise<string | null> {
    try {
      // Images are usually stored in the word/media directory
      const fullPath = imagePath.startsWith('/') ? `word${imagePath}` : `word/${imagePath}`;
      const imageFile = zip.file(fullPath);
      
      if (!imageFile) {
        return null;
      }
      
      const imageData = await imageFile.async('arraybuffer');
      const blob = new Blob([imageData]);
      
      // Convert to base64 for embedding
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error extracting image:', error);
      return null;
    }
  }
  
  async extractTextFromDocx(arrayBuffer: ArrayBuffer): Promise<string> {
    const zip = await JSZip.loadAsync(arrayBuffer);
    const documentXml = await zip.file('word/document.xml')?.async('text');
    
    if (!documentXml) {
      return '';
    }
    
    // Parse the XML content
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(documentXml, 'application/xml');
    
    // Extract text content from the XML
    return this.extractTextFromXml(xmlDoc);
  }
  
  private extractTextFromXml(xmlDoc: Document): string {
    // Get all text elements
    const textElements = xmlDoc.getElementsByTagName('w:t');
    let text = '';
    
    for (let i = 0; i < textElements.length; i++) {
      text += (textElements[i].textContent || '') + ' ';
    }
    
    return text.trim();
  }

  downloadFile() {
    if (this.fileSource) {
      this.botresponseservice.BotPreviewDocument(this.fileSource, this.botAppName)
        .subscribe(response => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = this.fileName || 'document.docx';
          link.click();
          window.URL.revokeObjectURL(url);
        });
    }
  }

  closeModal() {
    this.modalService.close();
    this.closeEvent.emit();
  }
  
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}