import { Component, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { AppComponentService } from '../Services/appcomponentservice';
import { WhatsNewService } from '../Services/whatsnew.service';
import { WhatsNewData, WhatsNewTranslation, WhatsNewSection } from '../model/whatsnew.model';

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.css']
})
export class WhatsnewComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  private modalJustOpened = false;
  
  currentLocale: string = 'en';
  whatsNewData: WhatsNewData | null = null;
  currentTranslation: WhatsNewTranslation | null = null;

  constructor(
    private modalService: ModalService,
    private i18n: I18nService,
    public appcomponentservice: AppComponentService,
    private elementRef: ElementRef,
    private whatsNewService: WhatsNewService
  ) {}

  // Listen for global click events
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Skip if the modal just opened to prevent immediate closing
    if (this.modalJustOpened) {
      this.modalJustOpened = false;
      return;
    }

    // Check if element exists and click is outside the modal
    const modalElement = this.elementRef.nativeElement.querySelector('.dds-modal');
    if (modalElement && !modalElement.contains(event.target) && 
        this.elementRef.nativeElement.contains(document.querySelector('.WhatsNewModel'))) {
      // Only close if the click was outside and our modal is currently in the DOM
      this.close();
    }
  }

  // Listen for escape key press
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close();
  }

  ngOnInit() {
    this.loadWhatsNewData();
    this.setupLocaleSubscription();
    
    // Set flag to prevent immediate closing
    this.modalJustOpened = true;
    
    // Reset flag after a short delay
    setTimeout(() => {
      this.modalJustOpened = false;
    }, 300);

    // Centering chat settings modal
    const sidebar = document.getElementById("sidebar");
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
  }

  loadWhatsNewData() {
    this.whatsNewService.getWhatsNewData()
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        this.whatsNewData = data;
        this.updateTranslation();
      });
  }

  setupLocaleSubscription() {
    this.i18n.getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.currentLocale = 'fr';
            break;
          case "ES":
            this.currentLocale = 'es';
            break;
          default:
            this.currentLocale = 'en';
        }
        this.updateTranslation();
      });
  }

  updateTranslation() {
    if (this.whatsNewData) {
      this.currentTranslation = this.whatsNewData[this.currentLocale as keyof WhatsNewData];
    }
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}