
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';
import { WhatsNewData } from '../model/whatsnew.model';

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {
  private whatsNewUrl = 'assets/data/whatnew.json';

  constructor(private http: HttpClient) { }

  
  getWhatsNewData(): Observable<WhatsNewData> {
    return this.http.get<WhatsNewData>(this.whatsNewUrl)
      .pipe(
        catchError(error => {
          console.error(`Failed to load whatsnew.json: ${error.message}`);
          return of(error);
        })
      );
  }

  
}