<div class="error-container">
    <div class="error-icon-container">
      <img class="error-icon" src="../../../assets/images/error.svg" alt="Error Icon">
    </div>
    <span class="error-message bold-message">{{ lblErrorHeader }}</span>
    <span class="error-details">
      {{ lblErrorDetailsPart1 }}
      <a [href]="teamsChannelUrl" target="_blank">{{ lblErorrDetailsTeamsChannel }}</a>
      {{ lblErrorDetailsPart2 }}
      <a [href]="serviceNowLink" target="_blank">{{ lblErrorDetailsHere }}</a>
      {{ lblErrorDetailsPart3 }}
    </span>
    <div class="error-button-container">
      <button class="back-button" (click)="goBack()">{{ lblGoBack }}</button>
    </div>
  </div>
  