import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetChatService {
  // Subject to emit a reset signal
  private resetSubject = new Subject<void>();

  // Observable to subscribe to the reset signal
  reset$ = this.resetSubject.asObservable();

  // Method to trigger the reset
  triggerReset() {
    this.resetSubject.next();
  }

  constructor() { }
}
