import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Agent } from "../model/agent.model";
import { AppComponentService } from "./appcomponentservice";



@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  constructor(private appComponentService: AppComponentService) {}
  private agents: Agent[] = [
    {
      id: 1,
      name: { en: "Document Analyzer", fr: "Document Analyzer", es:"Document Analyzer" },
      version: "",
      warning:  {
        en: "",
        fr: "",
        es: ""
      },
      description: {
        en: "I'm your data interpreter, parsing your uploaded files into detailed summaries and facilitating interactive chats with the processed information.",
        fr: "Je suis votre interprète de données. En celà, j'analyse vos fichiers téléchargés en résumés détaillés. Aussi, je facilite des discussions interactives avec les informations traitées.",
        es: "Soy tu intérprete de datos, analizando tus archivos subidos en resúmenes detallados y facilitando chats interactivos con la información procesada.",
      },
      isTooltipDisabled: true,
      path: "/document-analyzer",
      disabled: false
    },
    {
      id: 2,
      name: { en: "Deloitte Voice", fr: "Deloitte Voice", es: "Deloitte Voice" },
      version: "(beta)",
      warning:  {
        en: "*Access limited to selected testers. Release to all practitioners at a later date.",
        fr: "*L'accès est limité aux testeurs sélectionnés. Libérer à tous les praticiens à une date ultérieure.",
        es: "*Acceso limitado a testers seleccionados. Liberación para todos los practicantes en una fecha posterior."
      },
      description: {
        en: "Your content curator for Deloitte-style content creation and refinement.",
        fr: "Votre curateur de contenu pour la création et l'affinement de contenu en phase avec le style Deloitte.",
        es: "Tu curador de contenido para la creación y refinamiento de contenido al estilo Deloitte.",
      },
      isTooltipDisabled: true,
      path: "/deloitte-voice",
      disabled: false
    },
    {
      id: 3,
      name: { en: "EFS Chat", fr: "EFS Chat", es: "EFS Chat" },
      version: "(beta)",
      warning:  {
        en: "*Access limited to selected testers. Release to all practitioners at a later date.",
        fr: "*L'accès est limité aux testeurs sélectionnés. Libérer à tous les praticiens à une date ultérieure.",
        es: "*Acceso limitado a testers seleccionados. Liberación para todos los practicantes en una fecha posterior.",
      },
      description: {
        en: "Your financial insights guide to answer  Engagement Financial Services queries.",
        fr: "Votre guide des perspectives financières pour répondre aux questions des Services Financiers en matière de mandats.",
        es: "Tu guía de información financiera para responder consultas de Servicios Financieros de Compromiso.",
      },
      isTooltipDisabled: true,
      path: "/efs",
      disabled: false
    },
    {
      id: 4,
      name: { en: "Agent Bill", fr: "Agent Bill", es: "Agent Bill" },
      version: "(beta)",
      warning:  {
        en: "*Access limited to selected testers. Release to all practitioners at a later date.",
        fr: "*L'accès est limité aux testeurs sélectionnés. Libérer à tous les praticiens à une date ultérieure.",
        es: "*Acceso limitado a testers seleccionados. Liberación para todos los practicantes en una fecha posterior.",
      },
      description: {
        en: "I answer queries related to billing, collections, and DSO.",
        fr: "Je réponds aux questions relatives à la facturation, aux recouvrements et au DSO.",
        es: "Respondo consultas relacionadas con la facturación, las cobranzas y el DSO.",
      },
      isTooltipDisabled: true,
      path: "/billing",
      disabled: false
    },
    {
      id: 5,
      name: { en: "CRM Assist", fr: "CRM Assist", es: "CRM Assist" },
      version: "(beta)",
      warning:  {
        en: "*Access limited to selected testers. Release to all practitioners at a later date.",
        fr: "*L'accès est limité aux testeurs sélectionnés. Libérer à tous les praticiens à une date ultérieure.",
        es: "*Acceso limitado a testers seleccionados. Liberación para todos los practicantes en una fecha posterior.",
      },
      description: {
        en: "I assist with CRM how-to questions and inquiries related to O2E (Opportunity to Engagement) processes.",
        fr: "J'aide avec les questions sur l'utilisation du CRM et les demandes relatives aux processus O2E (Opportunité à Engagement).",
        es: "Asisto con preguntas sobre el uso del CRM y consultas relacionadas con los procesos O2E (Oportunidad a Compromiso).",
      },
      isTooltipDisabled: true,
      path: "/crm",
      disabled: false
    },
    // {
    //   id: 4,
    //   name: { en: "Web Crawler", fr: "Explorateur Web" },
    //   description: {
    //     en: "I'm your content extractor, pulling and cleaning content from any website URL you provide, delivering a streamlined and organized output.",
    //     fr: "Je suis votre extracteur de contenu, récupérant et nettoyant le contenu de tout URL de site web que vous fournissez, offrant un résultat simplifié et organisé."
    //   },
    //   isTooltipDisabled: false,
    //   path: "/web-crawler",
    //   disabled: true
    // },
    // {
    //   id: 5,
    //   name: { en: "WBS Code Finder", fr: "Rechercheur de codes WBS" },
    //   description: {
    //     en: "I'm your code navigator, assisting Deloitte employees in identifying the correct WBS code based on the provided work description.",
    //     fr: "Je suis votre navigateur de codes de facturation, aidant les employés de Deloitte à identifier le code WBS correct en fonction de la description du travail fournie."
    //   },
    //   isTooltipDisabled: false,
    //   path: "/wbs-code-finder",
    //   disabled: true
    // },
    // {
    //   id: 6,
    //   name: { en: "Firm Policy Helpdesk", fr: "Centre d'assistance des politiques de l'entreprise" },
    //   description: {
    //     en: "I'm your policy guide, searching the company policy database based on your query and returning precise answers with source file links.",
    //     fr: "Je suis votre guide des politiques de l'entreprise, recherchant dans la base de données des politiques en fonction de votre requête et renvoyant des réponses précises avec des liens vers les fichiers sources."

    //   },
    //   isTooltipDisabled: false,
    //   path: "/firm-policy-helpdesk",
    //   disabled: true
    // }
  ];

  getAgents(): Observable<Agent[]> {
    return of(this.agents);
  }

  getAgentById(id: number): Observable<Agent | undefined> {
    return of(this.agents.find(agent => agent.id === id));
  }

  getAgentByPath(path: string): Observable<Agent | undefined> {
    return of(this.agents.find(agent => agent.path === path));
  }

  updateEFS(): void {
    // debugger;
    const isEFSUser = this.appComponentService.isEFSUser; // Access isEFSUser from AppComponentService
    const efsAgent = this.agents.find(agent => agent.id === 3);
    if (efsAgent) {
      efsAgent.disabled = !isEFSUser; // If isEFSUser is true, it enables; otherwise, disables
      // efsAgent.isTooltipDisabled = isEFSUser;
    }
  }

  updateDeloitteVoiceAgent(){
    const isDVoiceUser = this.appComponentService.isDVoiceUser; 
    const dVoiceAgent = this.agents.find(agent => agent.id === 2);
    if (dVoiceAgent) {
      dVoiceAgent.disabled = !isDVoiceUser; 
    }
  }
  updateBillingBot(){
    const isBillingUser = this.appComponentService.isBillingUser; 
    const billingAgent = this.agents.find(agent => agent.id === 4);
    if (billingAgent) {
      billingAgent.disabled = !isBillingUser; 
    }
  }

  updateCrmBot(){
    const isCRMUser = this.appComponentService.isCRMUser; 
    const crmAgent = this.agents.find(agent => agent.id === 5);
    if (crmAgent) {
      crmAgent.disabled = !isCRMUser; 
    }
  }}
