import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeloitteVoiceStage } from '../deloitte-voice-stage.enum';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, Observable, Subscription, takeUntil, switchMap, interval, takeWhile, tap, catchError, throwError, of } from 'rxjs';
import { AppComponentService } from 'src/app/Services/appcomponentservice';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownOption } from 'src/app/componenets/select-dropdown/select-dropdown.component';
import { LoadingService } from 'src/app/Services/LoadingService';
import { TaskStatus as DeloitteVoiceTaskStatus } from "../task-status.enum";
import { DeloitteVoiceService } from 'src/app/Services/deloitte-voice.service';
import { DeloitteVoiceCreateRequest } from 'src/app/Services/interfaces/deloitte-voice-create-request.interface';
import { DeloitteVoiceFileTrackerResponseDto } from 'src/app/Services/interfaces/deloitte-voice-file-tracker-response.interface';
import { DeloitteVoiceCreateResponse } from 'src/app/Services/interfaces/deloitte-voice-create-response.interface';
import { ResetChatService } from 'src/app/Services/reset-chat.service';

@Component({
  selector: 'app-deloitte-voice-create',
  templateUrl: './deloitte-voice-create.component.html',
  styleUrl: './deloitte-voice-create.component.css'
})
export class DeloitteVoiceCreateComponent implements OnInit, OnDestroy {
  private resetSubscription: Subscription;
  private destroy = new Subject<void>();
  currentStage: DeloitteVoiceStage = DeloitteVoiceStage.STARTED;
  deloitteVoiceStage: typeof DeloitteVoiceStage = DeloitteVoiceStage;
  lblToolTips: string;
  lblToolTipsDesc: string;
  newTaskSubscription: Subscription;
  lblContentTopicBriefingDesc: string;
  lblContentTopicBriefing: string;
  lblBrieflyDescribe: string;
  form: FormGroup;
  readonly targetAudienceDefault: string = "General Public";
  readonly contentLengthDefault: string = "Very short";
  readonly contentMediumDefault: string = "Short form article";
  readonly overallStyleDefault: string = "Professional";
  lblTargetAudience: string;
  lblTargetAudienceDesc: string;
  lblOverallStyle: string;
  lblOverallStyleDesc: string;
  lblContentLength: string;
  lblContentLengthDesc: string;
  lblContentMedium: string;
  lblContentMediumDesc: string;
  lblOptionalReferenceFile: string;
  lblOptionalReferenceFileDesc: string;
  uploadedFile: File | null = null;
  inputFileName: string;
  outputFileName: string;
  lblStartProcessing: string;
  lblReset: string;
  lblAdditionalDetails: string;
  lblAdditionalDetailsTitle: string;
  lblAdditionalDetailsKeywordsInclude: string;
  lblAdditionalDetailsKeywordsExclude: string;
  lblSelectTA: string;
  lblPleaseSpecify: string;
  private pollingSubscription: Subscription;
  lblContentBeingRevised:string;
  lblFileReadyToDownload: string;
  readonly OTHER_VALUE = "Other";
  lblUploadLimitMessage: string;
  lblAdditionalUploadLimitMessage : string;
  
  maxFiles: number = 5;
  maxTotalSizeInMB: number = 10; 


  constructor(
    private i18n: I18nService,
    public appComponentService: AppComponentService,
    private router: Router,
    private deloitteVoiceService: DeloitteVoiceService,
    private fb: FormBuilder,
    public loadingService: LoadingService,
    private resetChatService: ResetChatService
  ) {
    this.form = this.fb.group({
      targetAudience: [this.targetAudienceDefault, Validators.required],
      overallStyle: [this.overallStyleDefault, Validators.required],
      contentLength: [this.contentLengthDefault, Validators.required],
      contentMedium: [this.contentMediumDefault, Validators.required],
      files: [[]],
      contentTopicBriefing: ['', [Validators.required, Validators.maxLength(3000000)]],
      targetAudienceTextbox: [''],
      contentMediumTextbox: [''],
      additionalDetailsTitle: ['', [Validators.maxLength(200)]],
      additionalDetailsKeywordsInclude: ['', [Validators.maxLength(200)]],
      additionalDetailsKeywordsExclude: ['', [Validators.maxLength(200)]],
    });  
  }

  dropdownTA: DropdownOption[] = [
    { label: { en: "General Public", fr: "Grand public", es: "Público General" }, value: "General Public" },
    { label: { en: "Industry Professional", fr: "Professionnel du secteur", es: "Profesional de la Industria" }, value: "Industry Professional" },
    { label: { en: "Client", fr: "Client", es: "Cliente" }, value: "Client" },
    { label: { en: "Student / Intern / Co-op", fr: "Étudiant / Stagiaire / Coopérant", es: "Estudiante / Pasante / Cooperante" }, value: "Student / Intern / Co-op" },
    { label: { en: "Other", fr: "Autre", es: "Otro" }, value: "Other" },
  ];
  
  dropdownOS: DropdownOption[] = [
    { label: { en: "Personable", fr: "Chaleureux", es: "Amigable" }, value: "Personable" },
    { label: { en: "Business casual", fr: "Décontracté professionnel", es: "Casual de Negocios" }, value: "Business casual" },
    { label: { en: "Professional", fr: "Professionnel", es: "Profesional" }, value: "Professional" },
    { label: { en: "Formal", fr: "Formel", es: "Formal" }, value: "Formal" },
  ];
  
  dropdownCL: DropdownOption[] = [
    { label: { en: "Very short", fr: "Très court", es: "Muy corto" }, value: "Very short" },
    { label: { en: "Concise", fr: "Concis", es: "Conciso" }, value: "Concise" },
    { label: { en: "Medium", fr: "Moyen", es: "Medio" }, value: "Medium" },
    { label: { en: "Elaborate", fr: "Détaillé", es: "Elaborado" }, value: "Elaborate" },
  ];
  
  dropdownCM: DropdownOption[] = [
    { label: { en: "Short form article", fr: "Article court", es: "Artículo de formato corto" }, value: "Short form article" },
    { label: { en: "Executive Summary", fr: "Résumé exécutif", es: "Resumen ejecutivo" }, value: "Executive Summary" },
    { label: { en: "Other", fr: "Autre", es: "Otro" }, value: "Other" },
  ];
  

  ngOnDestroy(): void {
    this.newTaskSubscription.unsubscribe();
    this.stopPolling();
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.loadingService.setLoadingState(false);
    }
  }
  

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblOverallStyle = "Style général";
            this.lblOverallStyleDesc = "Ton et voix généraux du contenu."
            this.lblTargetAudience = "Public cible";
            this.lblTargetAudienceDesc = "Groupe cible des consommateurs de contenu.";
            this.lblToolTips = "Conseils d’utilisation:";
            this.lblToolTipsDesc = "Fournissez un résumé du sujet, des paramètres et un fichier de référence, et Deloitte Voice rédigera le contenu. Toutes les directives de marque de l'entreprise sont intégrées dans l’outil, garantissant la conformité.";
            this.lblContentTopicBriefing = "Résumé du sujet du contenu";
            this.lblContentTopicBriefingDesc = "Fournissez un aperçu concis du sujet et du contenu à développer."
            this.lblBrieflyDescribe = "Décrivez brièvement le sujet et le contenu ici...";
            this.lblContentLength = "Longueur du contenu";
            this.lblContentLengthDesc = "Longueur souhaitée du contenu.";
            this.lblContentMedium = "Format du contenu";
            this.lblContentMediumDesc = "Plateforme ou format dans lequel le contenu sera diffusé.";
            this.lblOptionalReferenceFile = "Fichier de référence optionnel";
            this.lblOptionalReferenceFileDesc = "Fichier supplémentaire pouvant être utilisé comme référence ou contexte.";
            this.lblStartProcessing = "Lancer le traitement";
            this.lblReset = "Réinitialiser";
            this.lblAdditionalDetails = "Détails supplémentaires (Optionnel)";
            this.lblAdditionalDetailsTitle = "Titre";
            this.lblAdditionalDetailsKeywordsInclude = "Mots-clés à inclure (séparés par une virgule)";
            this.lblAdditionalDetailsKeywordsExclude = "Mots-clés à exclure (séparés par une virgule)";
            this.lblSelectTA = "Sélectionner le public cible";
            this.lblPleaseSpecify = "Veuillez préciser";
            this.lblContentBeingRevised = "Votre contenu est en cours de création. Cela peut prendre jusqu'à 1 heure. Une fois terminé, un lien de téléchargement de fichier sera fourni, où vous pourrez récupérer le contenu généré conformément au guide de style de l'entreprise. Merci pour votre patience.";            this.lblFileReadyToDownload = "Votre fichier est prêt à être téléchargé ! Merci de votre patience. Cliquez sur la carte du fichier ci-dessus pour télécharger votre document généré.";
            this.lblUploadLimitMessage = `Vous pouvez téléverser jusqu'à ${this.maxFiles} documents ou un total de ${this.maxTotalSizeInMB} Mo.`;
            this.lblAdditionalUploadLimitMessage = "(~150 pages de contenu en texte brut)";
            break;

          case "ES":
            this.lblOverallStyle = "Estilo General";
            this.lblOverallStyleDesc = "Tono y voz general del contenido."
            this.lblTargetAudience = "Público Objetivo";
            this.lblTargetAudienceDesc = "Grupo destinatario del contenido.";
            this.lblToolTips = "Informacion sobre Herramientas:";
            this.lblToolTipsDesc = "Proporcione un resumen del tema, parámetros y archivo de referencia, y Deloitte Voice redactará el contenido. Todas las directrices de la marca de la empresa están integradas en la herramienta, garantizando el cumplimiento.";
            this.lblContentTopicBriefing = "Resumen del Tema del Contenido";
            this.lblContentTopicBriefingDesc = "Proporcione una visión general concisa del tema y el contenido a desarrollar."
            this.lblBrieflyDescribe = "Describa brevemente el tema y el contenido aquí...";
            this.lblContentLength = "Extensión del Contenido";
            this.lblContentLengthDesc = "Longitud deseada del contenido.";
            this.lblContentMedium = "Medio del Contenido";
            this.lblContentMediumDesc = "Plataforma o formato en el que se entregará el contenido.";
            this.lblOptionalReferenceFile = "Archivo de Referencia Opcional";
            this.lblOptionalReferenceFileDesc = "Archivo adicional que se puede utilizar como referencia o contexto.";
            this.lblStartProcessing = "Iniciar Procesamiento";
            this.lblReset = "Restablecer";
            this.lblAdditionalDetails = "Detalles Adicionales (Opcional)";
            this.lblAdditionalDetailsTitle = "Título";
            this.lblAdditionalDetailsKeywordsInclude = "Palabras clave a incluir (use coma para separar)";
            this.lblAdditionalDetailsKeywordsExclude = "Palabras clave a excluir (use coma para separar)";
            this.lblSelectTA = "Seleccionar el público objetivo";
            this.lblPleaseSpecify = "Por favor especifique";
            this.lblContentBeingRevised = "Su contenido está siendo creado. Esto puede tardar hasta 1 hora. Una vez terminado, se proporcionará un enlace de descarga de archivo, donde podrá recuperar el contenido generado de acuerdo con la guía de estilo de la empresa. Gracias por su paciencia.";            this.lblFileReadyToDownload = "¡Su archivo está listo para descargar! Gracias por su paciencia. Por favor, haga clic en la tarjeta del archivo arriba para descargar su documento creado.";
            this.lblUploadLimitMessage = `Puedes subir hasta ${this.maxFiles} documentos o un total de ${this.maxTotalSizeInMB}MB de tamaño`;
            this.lblAdditionalUploadLimitMessage = "(~150 páginas de contenido en texto plano)";
          break;

          default:
            this.lblOverallStyle = "Overall Style";
            this.lblOverallStyleDesc = "General tone and voice of the content."
            this.lblTargetAudience = "Target Audience";
            this.lblTargetAudienceDesc = "Intended group of content consumers.";
            this.lblToolTips = "Tool Tips:";
            this.lblToolTipsDesc = "Provide a topic briefing, parameters, and reference file, and Deloitte Voice will draft the copy. All company branding guidelines are built into the tool, ensuring compliance.";
            this.lblContentTopicBriefing = "Content Topic Briefing";
            this.lblContentTopicBriefingDesc = "Provide a concise overview of the subject matter and the content to be developed."
            this.lblBrieflyDescribe = "Briefly describe the topic and content here...";
            this.lblContentLength = "Content Length";
            this.lblContentLengthDesc = "Desired length of the content.";
            this.lblContentMedium = "Content Medium";
            this.lblContentMediumDesc = "Platform or format in which the content will be delivered.";
            this.lblOptionalReferenceFile = "Optional Reference File";
            this.lblOptionalReferenceFileDesc = "Additional file that can be used for reference or context.";
            this.lblStartProcessing = "Start Processing";
            this.lblReset = "Reset";
            this.lblAdditionalDetails = "Additional Details (Optional)";
            this.lblAdditionalDetailsTitle = "Title";
            this.lblAdditionalDetailsKeywordsInclude = "Keywords to include (use comma to separate)";
            this.lblAdditionalDetailsKeywordsExclude = "Keywords to exclude (use comma to separate)";
            this.lblSelectTA = "Select target audience";
            this.lblPleaseSpecify = "Please specify";
            this.lblContentBeingRevised = "Your content is being created. This can take up to 1 hour. Once finished, a file download link will be provided, where you can retrieve the generated content adhering to the company's style guide. Thank you for your patience.";            this.lblFileReadyToDownload = "Your file is ready for download! Thank you for your patience. Please click the file card above to download your created document.";
            this.lblUploadLimitMessage = `You can upload up to ${this.maxFiles} documents or ${this.maxTotalSizeInMB}MB total in size`;
            this.lblAdditionalUploadLimitMessage = "(~150 pages of plain text content)";
          break;
        }
      }
    )
  }

  ngOnInit(): void {
    this.manageLocale();
    this.newTaskSubscription = this.appComponentService.newTask$.subscribe(() => {
      this.router.navigate([`/deloitte-voice`]);
    });
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      if (this.currentStage !== DeloitteVoiceStage.PROCESSING) {
        this.router.navigate([`/deloitte-voice`]);
      }
    });
  }

  onTargetAudienceChange(value: any) {
    if (value === this.OTHER_VALUE) {
      this.form.get('targetAudienceTextbox').setValidators(
        [
          Validators.required, 
          Validators.maxLength(50)
        ]);
    } else {
      this.form.get('targetAudienceTextbox').clearValidators();
      this.form.get('targetAudienceTextbox').reset();
    }
    this.form.get('targetAudienceTextbox').updateValueAndValidity();
  }

  onContentMediumChange(value: any) {
    if (value === this.OTHER_VALUE) {
      this.form.get('contentMediumTextbox').setValidators([Validators.required, Validators.maxLength(50)]);
    } else {
      this.form.get('contentMediumTextbox').clearValidators();
      this.form.get('contentMediumTextbox').reset();
    }
    this.form.get('contentMediumTextbox').updateValueAndValidity();
  }

  reset() {
    this.form.reset();
    this.form.patchValue({ targetAudience: this.targetAudienceDefault });
    this.form.patchValue({ contentLength: this.contentLengthDefault });
    this.form.patchValue({ contentMedium: this.contentMediumDefault });
    this.form.patchValue({ overallStyle: this.overallStyleDefault});
    this.form.patchValue({ files: [] });
  }

  isFormValid(): boolean {
    const targetAudience = this.form.get('targetAudience')?.value;
    const overallStyle = this.form.get('overallStyle')?.value;
    const contentLength = this.form.get('contentLength')?.value;
    const contentMedium = this.form.get('contentMedium')?.value;
    const files = this.form.get('files')?.value || [];
    const contentTopicBriefing = this.form.get('contentTopicBriefing')?.value || '';
    const targetAudienceTextbox = this.form.get('targetAudienceTextbox')?.value || '';
    const contentMediumTextbox = this.form.get('contentMediumTextbox')?.value || '';

    // Check if all required fields are filled
    const isDropdownsValid = targetAudience && overallStyle && contentLength && contentMedium;
    const isContentTopicBriefingValid = contentTopicBriefing.length > 0 && contentTopicBriefing.length <= 3000000;
    const isOtherDetailsValid = targetAudience !== this.OTHER_VALUE || (targetAudience === this.OTHER_VALUE && targetAudienceTextbox.length > 0 && targetAudienceTextbox.length <= 50);
    const iscontentMediumTextboxValid = contentMedium !== this.OTHER_VALUE || (contentMedium === this.OTHER_VALUE && contentMediumTextbox.length > 0 && contentMediumTextbox.length <= 50);

    return isDropdownsValid && isContentTopicBriefingValid && isOtherDetailsValid && iscontentMediumTextboxValid;
  }


  process(): void {
    this.outputFileName = "";
    this.appComponentService.disableNewTaskButton();
    this.currentStage = DeloitteVoiceStage.PROCESSING;
    this.loadingService.setLoadingState(true);
    const hasFilesToUpload: File[] = this.form.get('files')?.value || [];

    if (hasFilesToUpload.length) {
      this.uploadDocument().subscribe({
        next: response => {
          this.processDocuments().subscribe({
            next: processResponse => {
              this.postProcessSuccess(processResponse);
            },
            error: error => {
              this.handleProcessError(error);
            }
          });
        },
        error: error => {
          this.handleProcessError(error);
        }
      });
    } else {
      this.processDocuments().subscribe({
        next: response => {
          this.postProcessSuccess(response);
        },
        error: error => {
          this.handleProcessError(error);
        }
      });
    }
  }

  private uploadDocument(): Observable<any> {
    const files: File[] = this.form.get('files')?.value || [];
    const formData = new FormData();
    files.forEach(item => {
      formData.append('files', item, item.name);
    });
    formData.append('container', "");
    formData.append('folderName', "");
    this.inputFileName = files[0]?.name;
  
    return this.deloitteVoiceService.uploadDocument(formData);
  }

  private processDocuments(): Observable<any> {
    const {
      contentTopicBriefing = "",
      targetAudience = "",
      targetAudienceTextbox = "",
      overallStyle = "",
      contentLength = "",
      contentMedium = "",
      contentMediumTextbox = "",
      files = [],
      additionalDetailsTitle = "",
      additionalDetailsKeywordsInclude = "",
      additionalDetailsKeywordsExclude = "",
    } = this.form.value;;
  
    const processedTargetAudience = targetAudience === this.OTHER_VALUE 
    ? targetAudienceTextbox 
    : targetAudience;
    const processedContentMedium = contentMedium === this.OTHER_VALUE 
    ? contentMediumTextbox 
    : contentMedium;
    const fileNames =  files.map(file => file.name);
  
    const request: DeloitteVoiceCreateRequest = {
      contentTopicBriefing,
      targetAudience: processedTargetAudience,
      overallStyle,
      contentLength,
      contentMedium: processedContentMedium,
      fileNames,
      additionalDetailsTitle,
      additionalDetailsKeywordsInclude,
      additionalDetailsKeywordsExclude,
    };
    return this.deloitteVoiceService.createDocument(request);
  }
  

  private postProcessSuccess(response: DeloitteVoiceCreateResponse): void {
    this.startPolling(response.taskId);
  }
   
  private postPolling(fileName: string): void {
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
    this.outputFileName = fileName;
    this.currentStage = DeloitteVoiceStage.COMPLETED;
  }
   
  private handleProcessError(error: any): void {
    this.currentStage = DeloitteVoiceStage.FAILED;
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
  }
   
   startPolling(taskId: string): void {
     this.pollingSubscription = interval(30000) // Poll every 30 seconds
       .pipe(
         switchMap(() =>
           this.deloitteVoiceService.getStatus(taskId).pipe(
             catchError(error => {
               this.stopPolling();
               this.handleProcessError(error);
               return throwError(error);
             })
           )
         ),
         tap((response : DeloitteVoiceFileTrackerResponseDto) => {
           if (response.status === DeloitteVoiceTaskStatus.Completed) {
             this.postPolling(response.outputFileName);
             this.stopPolling();
           } else if (response.status === DeloitteVoiceTaskStatus.Failed) {
             this.handleProcessError(response);
             this.stopPolling();
           }
         }),
         takeWhile((response : DeloitteVoiceFileTrackerResponseDto) =>
           response.status === DeloitteVoiceTaskStatus.Pending ||
         response.status === DeloitteVoiceTaskStatus.InProgress)
       )
       .subscribe(
         response => {
         },
         error => {
           console.error('Polling error in subscribe:', error);
         }
       );
   }
 
   
  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }
   
  goBack(): void {
    this.currentStage = DeloitteVoiceStage.STARTED;
  }

  handleDownloadFailed(): void {
    this.currentStage = DeloitteVoiceStage.FAILED;
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
  }

}
