import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
import { Observable } from 'rxjs';
import { DeloitteVoiceReviseRequest } from './interfaces/deloitte-voice-revise-request.interface';
import { DeloitteVoiceCreateRequest } from './interfaces/deloitte-voice-create-request.interface';

@Injectable({
  providedIn: 'root'
})
export class DeloitteVoiceService {
  constructor(private httpClient: HttpClient) { }
  baseUrl = protectedResources.apiDBot.endpoint;

uploadDocument(formData: FormData): any {
  const url = `${this.baseUrl}api/DeloitteVoice/UploadDocument`;
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.post(  
    url,    
    formData   
   );
  }

  downloadFile(fileName: string): Observable<Blob> {
    const url = `${this.baseUrl}api/DeloitteVoice/DownloadDocument?fileName=${fileName}`;
    return this.httpClient.get(url, { responseType: 'blob' });
}

reviseDocument(request: DeloitteVoiceReviseRequest): Observable<any> {
  const url = `${this.baseUrl}api/DeloitteVoice/revise`;
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post<any>(url, request, { headers });
}

createDocument(request: DeloitteVoiceCreateRequest): Observable<any> {
  const url = `${this.baseUrl}api/DeloitteVoice/create`;
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post<any>(url, request, { headers });
}

getStatus(taskId: string): Observable<any> {
  const url = `${this.baseUrl}api/DeloitteVoice/status/${taskId}`;
  return this.httpClient.get(url);
}


}

