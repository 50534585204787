 <div #dropdownContainer class="dropdown-container">
    <div class="dropdown-button" [attr.aria-expanded]="isOpen" (click)="toggleDropdown()">
      <span>{{ selectedOption?.label[language] || placeholder }}</span>
      <img src="../../../assets/images/dropdown-down.svg" class="icon" />
    </div>
  
    @if (isOpen) {
      <div class="dropdown-options">
        @for (option of options; track option.value) {
          <div
            class="dropdown-option"
            [class.disabled]="option.disabled"
            (click)="selectOption(option)"
            [attr.aria-disabled]="option.disabled">
            {{ option.label[language] }}
          </div>
        }
      </div>
    }
  </div>