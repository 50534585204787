<div class="filePreviewModal">
  <div class="dds-modal-overlay" (click)="closeModal()"></div>

  <div ddsResize class="dds-modal" [ngClass]="{'middleEFSPreviewFileModelPopup': appcomponentservice.isPinChecked}">
    <div class="dds-modal__header">
      <span class="txtFeedback">{{lblFilePreview}}</span>
      
      <dds-button size="sm"
                  icon="dds-icon_close"
                  kind="silent"
                  theme="dark"
                  ariaLabel="Close modal"
                  class="dds-modal__close ddsBtnSelf"
                  (clicked)="closeModal()"></dds-button>
    </div>

    <hr class="line1">
    <span>{{ fileName }}</span>
    <div class="dds-modal__body">
      <!-- Loading spinner shown immediately when modal opens -->
      <div *ngIf="isLoading" class="loading-indicator">
        <div class="loading-spinner"></div>
        <div class="loading-text">{{lblLoadingDoc}}</div>
      </div>
      
      <!-- Document content shown only after loading is complete -->
      <div #docxContainer class="docx-container" [style.display]="isLoading ? 'none' : 'block'">
        <div class="preview-content">
          <div *ngIf="!isLoading && error" class="error">{{ error }}</div>
          <div *ngIf="!isLoading && !error" [innerHTML]="documentContent" class="text-content"></div>
        </div>
      </div>
    </div>
    <button class="dds-btn" (click)="downloadFile()">{{lblDownload}}</button>
  </div>
</div>