import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatTextHeightServiceService {

  constructor() { }

  private adjustTextareaHeightSource = new Subject<HTMLTextAreaElement>();
  adjustTextareaHeight$ = this.adjustTextareaHeightSource.asObservable();

  private resetTextareaHeightSource = new Subject<void>();
  resetTextareaHeight$ = this.resetTextareaHeightSource.asObservable();

  triggerAdjustTextareaHeight(textarea: HTMLTextAreaElement) {
    this.adjustTextareaHeightSource.next(textarea);
  }

  triggerResetTextareaHeight() {
    this.resetTextareaHeightSource.next();
  }
}
