import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ButtonKind, Themes } from '@usitsdasdesign/dds-ng/shared';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import moment from 'moment';
import { Subscription, Subject, BehaviorSubject, takeUntil, of, delay, concatMap } from 'rxjs';
import { LoadingService } from 'src/app/Services/LoadingService';
import { AppComponentService } from 'src/app/Services/appcomponentservice';
import { ChatService } from 'src/app/Services/chat.service';
import { RecentAgentsService } from 'src/app/Services/recent-agent.service';
import { ResetChatService } from 'src/app/Services/reset-chat.service';
import { environment } from 'src/app/environments/environment';
import { FeedbackDisLikeComponent } from 'src/app/feedback-dis-like/feedback-dis-like.component';
import { FeedbackLikeComponent } from 'src/app/feedback-like/feedback-like.component';
import { v4 as uuidv4 } from 'uuid';
import { DocumentUploadService } from 'src/app/Services/document-upload.service';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { ResetBlobStorageService } from 'src/app/Services/reset-blob-storage.service';
import { ChatTextHeightServiceService } from 'src/app/Services/chat-text-height-service.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-chat-window',
  standalone: false,
  templateUrl: './chat-window.component.html',
  styleUrl: './chat-window.component.css'
})
export class ChatWindowComponent {
    private resetSubscription: Subscription;
    isProcessingCompleted: boolean = false;
    selectedFiles: File[] = [];
    isUploadLimitExceeded: boolean = false; // Flag for upload limit exceeded
    MAX_FILE_SIZE = 50 * 1024 * 1024; // Max total file size in bytes (50 MB)
    MAX_FILE_COUNT = 5; // Max number of files
    cancelButtonKind = ButtonKind.secondaryLoud;
    primaryButtonTheme = Themes.green;
    primaryButtonKind = ButtonKind.primaryLoud;
    private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
    private destroy = new Subject<void>();
    lblNameGreeting: string;
    lblHelpGreeting: string;
    lblHiName: string;
    lblGenDIntro1: string;
    lblGenDIntro2: string;
    lblEFS_01: string;
    KB_URL: string;
    lblAskQuestion: string;
  
    txtUploadDocs: string;
    lblDragHere: string;
    txtYouCanUpload: string;
    lblStartProcessing: string;
    txtReachedUploadLimit: string;
    btnStartProcessing: string;
    btnReset: string;
    lblAskFirstQues: string;
  
    userName: any;
    feedbacklike: any;
    userQuery: string;
    isCopy: boolean;
    userResponse: string;
    isStreaming: boolean;
    dragDropElement: any;
    dragDropEnabled: any;
    FileNames: string[] = [];
    errorCount: any;
    gotSummaryResp: boolean;
    disableProcessing: boolean = false;
    errorMesg: any;
    references: any;
    docerrorMessage: boolean = false;
    ErrorMessage: string;
    docStatusMessage: any;
    StatusMessage: any;
    StatusMessageUpload: string;
    StatusMessageProcess: string;
    StatusMessagePrepare: string;
    loadmsgprc: any;
    loadmsgEmb: any;
    loadmsgSumry: any;
    resetDisabled: boolean = true;
    displayName: any;
    lblSorrySomething2: string;
    lblSorrySomething1: string;
    lblUserCommunity: string;
    verifyEFSvalue: boolean = true;
    copyIndex: any;
    typesToUpload: string;
    private subscriptions: Subscription[] = [];

    constructor(private modalService: ModalService, private chatService: ChatService, public loadingService: LoadingService,
      public appcomponentservice: AppComponentService, private render: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef,
      private https: HttpClient, private DocumentUploadService: DocumentUploadService, private i18n: I18nService,
      private ResetBlobStorageService: ResetBlobStorageService, private resetChatService: ResetChatService, private recentAgentsService: RecentAgentsService, private router: Router,
      private chatservice: ChatService, public chatTextHeightServiceService: ChatTextHeightServiceService) {
  
    }
  
    isLoading = false;
    disableSendButton = false;
    isDefaultText: boolean;
    userPrompt: string;
    systemModel = "GPT 3.5"
    systemMessage: string;
    responseStyle: string;
    guid = uuidv4()
    userId: string;
    regenerateFlag = false;
    userQueryLength;
    likedIndex: number = -1;
    dislikedIndex: number = -1;
    isLiked: boolean = false;
    isDisLiked: boolean = false;
    likedIndices: Set<number> = new Set();
    dislikedIndices: Set<number> = new Set();
    disabledLikeIndices = new Set<number>();
    disabledDisLikeIndices = new Set<number>();
    @ViewChild('chatContainer') chatContainer!: ElementRef;
    @ViewChild('dynamicTextarea') dynamicTextarea!: ElementRef;
    textareaRef: any;
    messages = new BehaviorSubject<{ user: string; text: string }[]>([
    ]);
    messages$ = this.messages.asObservable();
    private defaultHeight: number = 50.5;
  
    autoScrollEnabled: boolean = true;
    lastScrollTop: number = 0;
    showScrollToBottom: boolean = false;
    lblIndexLastRefreshDate: string;
    // New methods for file upload
  
    
  
    onScroll(event: Event): void {
      const container = this.chatContainer.nativeElement;
      const currentScrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
  
      // Check if user is scrolling down or up
      if (currentScrollTop < this.lastScrollTop) {
        this.autoScrollEnabled = false;
      }
  
      // Determine if the user is at the bottom
      const atBottom = scrollHeight - clientHeight <= currentScrollTop + 15;
  
      // Update the flag to show/hide the Scroll to Bottom button
      this.showScrollToBottom = !atBottom;
  
      if (atBottom) {
        this.autoScrollEnabled = true;
      }
  
      // Update the last scroll position
      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For negative scrolling
    }
  
    OnScrollToBottom() {
      setTimeout(() => {
        this.autoScrollEnabled = true;
      }, 590);
      this.scrollToBottomSlow();
    }
  
    scrollToBottomSlow() {
      const container = this.chatContainer.nativeElement;
      const target = container.scrollHeight; // Total scrollable height
      const start = container.scrollTop; // Current scroll position
      const distance = target - start; // Distance to scroll
      const duration = 600; // Duration of the scroll in milliseconds
  
      let startTime: number | null = null;
  
      const animation = (currentTime: number) => {
        if (startTime === null) startTime = currentTime; // Initialize start time
        const timeElapsed = currentTime - startTime; // Time elapsed since start
        const progress = Math.min(timeElapsed / duration, 1); // Normalize progress (0 to 1)
  
        // Easing function for smooth scrolling
        const ease = easeInOutQuad(progress);
        container.scrollTop = start + distance * ease; // Update scroll position
  
        if (timeElapsed < duration) {
          requestAnimationFrame(animation); // Continue the animation
        } else {
          this.showScrollToBottom = false; // Hide the button after scrolling
        }
      };
  
      requestAnimationFrame(animation); // Start the animation
  
      // Easing function: ease in-out
      function easeInOutQuad(t: number) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }
    }
   
    manageLocale(): void {
      this.i18n
        .getLocaleObs()
        .pipe(takeUntil(this.destroy))
        .subscribe((locale) => {
          switch (locale) {
            case "FR":
              this.lblNameGreeting = "Bonjour";
              this.lblHelpGreeting = "Je suis l'agent EFS, votre expert pour tout ce qui a trait à EFS. Comment puis-je vous aider aujourd'hui?";
              this.lblGenDIntro1 = "FR Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
              this.lblGenDIntro2 = "Conteneur de données mis à jour: ";
              this.lblAskQuestion = "Posez moi n'importe quelle question";
              this.ErrorMessage = "Désolé, une erreur s'est produite. Veuillez réessayer ultérieurement."; //Gen-D Translations above 1 and below 3 too
              this.lblAskFirstQues = "Posez votre première question sur vos fichiers téléchargés pour commencer";
              this.lblSorrySomething1 = "Oops, quelque chose ne va pas. Veuillez réessayer. Si le problème persiste, veuillez vous rendre sur la ";
              this.lblSorrySomething2 = " et publier votre problème dans le canal 'Discussion'. Notre équipe s'occupera de ce problème dès que possible.";
              this.lblUserCommunity = "Communauté d'utilisateurs";
              this.lblIndexLastRefreshDate = "récupération..."
              break;
            case "ES":
              this.lblNameGreeting = "Hola";
              this.lblHelpGreeting = "Soy el agente EFS, tu experto en el area de EFS. ¿Cómo puedo ayudarte hoy?";
              this.lblGenDIntro1 = "Gen-D es una herramienta de IA que te ayuda a trabajar más rápido. ¡Pregúntame cualquier cosa y haré lo mejor para ayudarte!";
              this.lblGenDIntro2 = "Actualización del deposito de datos: ";
              this.lblEFS_01 = "2 de enero de 2025";
              this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
              this.lblAskQuestion = "Hazme cualquier pregunta...";
              this.lblIndexLastRefreshDate = "recuperando..."
              break;
            default:
              this.lblNameGreeting = "Hi";
              this.lblHelpGreeting = "I am EFS agent, your EFS subject matter expert. How can I help you today?";
              this.lblGenDIntro1 = "Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
              this.lblGenDIntro2 = "Data bucket updated: ";
              this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
              this.lblAskQuestion = "Ask me any question...";
              this.lblStartProcessing = "Upload your files and click 'Start Processing'. We'll handle the preprocessing in the background for about 3-5 minutes. Once it's done, the chat input field will be unlocked, and you can start chatting with your document.";
              this.ErrorMessage = "Sorry, something went wrong. Please try again later.";
              this.lblSorrySomething1 = "Oops, something is wrong. Please try again. If the issue persists, please head to the ";
              this.lblSorrySomething2 = " and post your issue in the 'Discussion' channel. Our team will attend the issue asap.";
              this.lblUserCommunity = "User Community";
              this.lblIndexLastRefreshDate = "retrieving..."
              break;
          }
          // alert(locale)
        });
    }

  
    ngOnInit() {
      this.subscriptions.push(
        this.chatTextHeightServiceService.adjustTextareaHeight$.subscribe(textarea => {
          this.adjustTextareaHeight(textarea);
        })
      );
      this.appcomponentservice.triggerNewChatFunction$.subscribe(() => {
        this.newChat();
      });
  
      this.subscriptions.push(
        this.chatTextHeightServiceService.resetTextareaHeight$.subscribe(() => {
          this.resetTextareaHeight();
        })
      );
      this.manageLocale();
      this.getProfile();
    }
  
    ngOnDestroy() {
      // Unsubscribe to prevent memory leaks
      if (this.resetSubscription) {
        this.resetSubscription.unsubscribe();
        this.loadingService.setLoadingState(false);
        
      }
      
    }
    async sendMessage(regenerateFlag: boolean, responsetext: any) {
      this.userPrompt  = "";
      const params = {
        regenrateflag: regenerateFlag,
        userPrompt: responsetext
      };
      this.appcomponentservice.SendMessageFunction(params);
    //  this.disableSendButton = false;
   // this.clearTextarea();
    }
    setUserMessage(text: string) {
      this.userResponse = text;
    }
   
    // adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    //   if (!textarea) return;
    //   const atBottom = this.isAtBottom(this.chatContainer.nativeElement);
    //   textarea.style.height = 'auto';
    //   const maxHeight = 100; // Max height for the textarea
    //   const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height
    //   textarea.style.height = `${newHeight}px`;
    //   if (atBottom) {
    //     this.scrollToBottom();
    //   }
    // }

    adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
      if (!textarea) return;
      const atBottom = this.isAtBottom(this.chatContainer?.nativeElement);
      if (!atBottom) return;
      
      textarea.style.height = 'auto';
      const maxHeight = 100; // Max height for the textarea
      const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height
      textarea.style.height = `${newHeight}px`;
      if (atBottom) {
        this.scrollToBottom();
      }
    }

    newChat() {
           this.userPrompt = "";
      
    }
    
    resetTextareaHeight(): void {
      const textarea = this.dynamicTextarea.nativeElement;
      if (!textarea) return;
      textarea.style.height = `${this.defaultHeight}px`;
    }
    // isAtBottom(container: HTMLElement): boolean {
    //   const scrollHeight = container.scrollHeight;
    //   const clientHeight = container.clientHeight;
    //   const currentScrollTop = container.scrollTop;
  
    //   // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
    //   return scrollHeight - clientHeight <= currentScrollTop + 15;
    // }

    isAtBottom(container: HTMLElement): boolean {
      if (!container) return false;
      
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const currentScrollTop = container.scrollTop;
    
      // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
      return scrollHeight - clientHeight <= currentScrollTop + 15;
    }
  
    // onInput() {
    //   this.chatTextHeightServiceService.triggerAdjustTextareaHeight(this.dynamicTextarea.nativeElement);
    // }

    onInput() {
      if (this.dynamicTextarea) {
        this.chatTextHeightServiceService.triggerAdjustTextareaHeight(this.dynamicTextarea.nativeElement);
      }
    }
   
    scrollToBottom(): void {
      try {
        setTimeout(() => {
          const chatContainer = this.chatContainer.nativeElement as HTMLElement;
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 0);
      } catch (err) {
        console.error('Scroll to bottom failed:', err);
      }
  
    }
    getProfile() {
      this.https.get(GRAPH_ENDPOINT)
        .subscribe((profile: any) => {
          this.userName = profile.displayName;
          this.userId = profile.mail;
          this.displayName = profile.givenName;
        })
    }
    
    handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
      if (event.key === 'Enter' && !event.shiftKey) {
        // If Enter is pressed without Shift, call sendMessage function
        if (this.userPrompt.trim() === "" || this.appcomponentservice.isSendButtonDisabled) {
          event.preventDefault(); // Prevent the default action of adding a newline
          return;
        }
        this.sendMessage(false, this.userPrompt);
        event.preventDefault(); // Prevent the default action of adding a newline
      }
  
    }
    clearTextarea(): void {
      const textarea = this.dynamicTextarea.nativeElement;
      if (!textarea) return;
  
      textarea.value = '';
    }

    // ngAfterViewInit() {
    //   const textarea = this.dynamicTextarea.nativeElement;
    //   // Ensure it starts with the correct height
    //   textarea.style.height = `${this.defaultHeight}px`;
    // }

    ngAfterViewInit() {
      const textarea = this.dynamicTextarea?.nativeElement;
      if (textarea) {
        // Ensure it starts with the correct height
        textarea.style.height = `${this.defaultHeight}px`;
      }
      
      // Subscribe to the service here if needed
      this.subscriptions.push(
        this.chatTextHeightServiceService.adjustTextareaHeight$.subscribe(textarea => {
          this.adjustTextareaHeight(textarea);
        })
      );
    
      this.subscriptions.push(
        this.chatTextHeightServiceService.resetTextareaHeight$.subscribe(() => {
          this.resetTextareaHeight();
        })
      );
    }
}