<div id="homeContent" class="home-content">
    <div class="content-container" #chatContainer (scroll)="onScroll($event)">
    <div class="content-wrapper centered-text" [hidden]="!isDefaultText">
        <div class="txtHiNameStyle">
            <img src="../assets/images/rocket.png" />
            <span class="txtHiNameStyle2"> {{lblNameGreeting}}, {{displayName}}!</span>
        </div>
        <div class="txtPart2Style">
            {{lblHelpGreeting}}
        </div>
        <div class="prompts">
            <div class="prompt-card" (drop)="onFileDropped($event)" (dragover)="onDragOver($event)" (click)="triggerFileUpload()">
                <input type="file" accept=".pdf,  .docx, .vtt, .pptx, .xlsx, .xls, .html, .txt" #fileInput (change)="onFileSelected($event)" multiple style="display: none;">
                <div class="txtSamplePrompts">
                    {{lblDragHere}}
                </div>
                <div class="txtSamplePrompts txtSamplePromptBold">
                    {{txtYouCanUpload}}
                </div>
                <div class="txtSamplePrompts txtsamplepadding">
                    {{typesToUpload}}
                </div>
            </div>
        </div>
    </div>
    <div class="content-wrapper centered-text" *ngIf="(!isDefaultText && (messages$ | async)?.length === 0) ">
        <div class="txtPart3Style">
            {{lblStartProcessing}}
        </div>
    </div>
    <div class="dynamic-content" id="dynamicContent" [hidden]="isDefaultText">
        <ng-container *ngFor="let message of messages$ | async let i = index">
            <div class="content-with-icon userPrompt" *ngIf="message.user === 'user'">
                <i class="fa fa-user-circle imgDynamicContent1" aria-hidden="true"></i>
                <div class="message">
                    {{ message.text }}
                </div>
                <ng-container *ngIf="setUserMessage(message.text)"></ng-container>
            </div>

            <div class="bot-content-with-icon botResponse" *ngIf="message.user === 'bot'">
                <!-- <img class="imgDynamicContent2" src="../assets/images/sparkles.png" /> -->
                <i class="imgDynamicContent2Background"><img class="imgDynamicContent2 " src="../assets/images/sparkles.png" /></i>

                <div class="botmessage">
                    <markdown>{{ message.text }}</markdown>
                </div>
                <div class="thumbs-icons" *ngIf="i != messages.value.length -1|| !isStreaming">
                    <img *ngIf="!this.dislikedIndices.has(i) && !likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeFinal.png" (click)="openFeedbackLikeModel(i, message.text)">
                    <img *ngIf="!this.likedIndices.has(i) && !dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeFinal.png" (click)="openFeedbackDislikeModel(i, message.text)">
                    <img *ngIf="dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeIconGreen.png">
                    <img *ngIf="likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeIconGreen.png">
                    <img *ngIf="!isCopy || i != copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/clipboard.png" (click)="copyToClipboard(message.text, i)" />
                    <img *ngIf="isCopy && i === copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/check-circle.png" />
                    
                </div>
            </div>
        </ng-container>
    </div>
</div>
    <div class="chat-main-container" id="chatMainContainer" [hidden]="isDefaultText">
        <!-- <div class="scroll-bottom-container" *ngIf="showScrollToBottom" (click)="OnScrollToBottom()">
            <i class="fa fa-arrow-down" aria-hidden="true"></i>
        </div> -->
        <div *ngIf="docerrorMessage" class="error-message">
            {{lblSorrySomething1}} <span class="userCommunity" (click)="openUserCommunityLink()">
                {{lblUserCommunity}}</span>{{lblSorrySomething2}}
        </div> 
        <div *ngIf="docStatusMessage" class="status-message">
            {{StatusMessage}}
        </div>
        <div class="upload-container">
            <div class="uploaded-files-row">
                <div class="uploaded-file-item" *ngFor="let file of selectedFiles">
                    {{ file.name }} <button [hidden]="disableProcessing" class="file-remove-btn" (click)="removeFile(file)">
                        <img class="file-remove-btn" src="../assets/images/cancel.png">
                    </button>
                </div>
            </div>

            <div class="action-buttons-row">
                <div (click)="triggerFileUpload2()" [ngClass]="{'attachment-icon-disable':  disableProcessing}" class="attachment-icon">
                    <img class="attachment-icon-img" src="../assets/images/paper-clip.png" />
                    <span class="attachment-error" *ngIf="isUploadLimitExceeded">
                        {{txtReachedUploadLimit}}
                    </span>

                    <input [disabled]="disableProcessing" type="file" accept=".pdf, .docx, .vtt, .pptx, .xlsx, .xls, .html, .txt" #fileInput2 (change)="onFileSelected($event)" multiple style="display: none;">

                </div>
                <button class="btn process-btn" (click)="startProcessing()" [disabled]="isUploadLimitExceeded || selectedFiles.length === 0 || disableProcessing">
                    {{btnStartProcessing}}
                </button>
                <button (click)="resetDocuments()" class="btn reset-btn"  [disabled]="resetDisabled">
                    {{btnReset}}
                </button>
            </div>
        </div>

        <div class="chat-input" id="chatinput">
            <!-- <textarea rows="1" placeholder={{lblAskFirstQues}} [(ngModel)]='userPrompt' #dynamicTextarea (input)="adjustTextareaHeight(dynamicTextarea)" (keydown)="handleKeydown($event, dynamicTextarea)" class="userinput" [disabled]="!isProcessingCompleted"></textarea> -->
            <textarea rows="1" placeholder={{lblAskFirstQues}} [(ngModel)]='userPrompt'
            (input)="adjustTextareaHeight(dynamicTextarea)"   #dynamicTextarea
            (keydown)="handleKeydown($event, dynamicTextarea)" class="userinput" [disabled]="!isProcessingCompleted"></textarea>
            <button type="submit" class="send-button" (click)="sendMessage(false, userPrompt)" (keyup.enter)="sendMessage(false, userPrompt)" [disabled]="disableSendButton">
                <img [ngClass]="{'send-button-disable':  disableSendButton}" id="imgSendStyle" src="../assets/images/send_new.png" />
            </button>
        </div>
    </div>
  
</div>