import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';
import { SharingmessageService } from '../Services/sharingmessage.service';
 
@Directive({
  selector: '[appDragdropfile]'
})
export class DragdropfileDirective {
  @Input() isDragDropEnabled: boolean = true;
 
  constructor(private sharingService: SharingmessageService) {}
 
  @HostBinding('class.fileover') fileOver: boolean;
 
  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
 
  @HostListener('dragover', ['$event'])
  onDragover(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.isDragDropEnabled) {
      this.fileOver = true;
    }
  }
 
  @HostListener('dragleave', ['$event'])
  onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.isDragDropEnabled) {
      this.fileOver = false;
    }
  }
 
  @HostListener('drop', ['$event'])
  onDrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    if (this.isDragDropEnabled) {
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.filesDropped.emit(Array.from(files));
      }
      this.sharingService.setFileData(files);
    }
  }
 
  setIsDragDropEnabled(enabled: boolean): void {
    this.isDragDropEnabled = enabled;
  }
}