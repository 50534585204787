import { Component, inject } from '@angular/core';
import { AppComponentService } from '../Services/appcomponentservice';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { Agent } from '../model/agent.model';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { AgentsService } from '../Services/agent.service';
import { ChatService, UserAccessResponse } from '../Services/chat.service';
import { AppComponent } from '../app.component';
import { LoadingService } from '../Services/LoadingService';
import { BotResponseService } from '../Services/bot-response.service'; 
@Component({
    selector: 'app-agent-central',
    templateUrl: './agent-central.component.html',
    styleUrls: ['./agent-central.component.css']
})
export class AgentCentralComponent {
    language: string = 'en'
    searchTerm: string = '';
    searchDisabled: boolean = false;
    selectedCategory: string = '';
    searchResultsVisible: boolean = false; 
    currentIndex: number = 0;   
    private destroy = new Subject<void>(); 
    // Labels
    lblPageTitle: string;
    lblPageDescriptionPart1: string;
    lblPageDescriptionPart2: string = " for a detailed introduction."
    tutorialLink: string = "#";
    tutorialLinkDescription: string = "Tutorial Video";
    lblPopularAgents:string;
    lblAllAgents: string;
    ComingSoontext: string = "Coming Soon!"
    allAgents: Agent[] = [];
    filteredAgents: Agent[] = this.allAgents;

    constructor(public appComponentService: AppComponentService,
        private agentService : AgentsService,
        private router: Router, private botResponseService:BotResponseService,
        public loadingService: LoadingService,
        private i18n: I18nService, private chatservice:ChatService) {}

        manageLocale(): void {
            this.i18n
              .getLocaleObs()
              .pipe(takeUntil(this.destroy))
              .subscribe((locale) => {
                switch (locale) {
                  case "FR":
                    this.language = 'fr'
                    this.lblPageTitle = "Explorer les agents IA";
                    this.lblPopularAgents = "FR - Popular Agents"
                    this.lblAllAgents = "Tous les agents"
                    this.lblPageDescriptionPart1 = "Bienvenue à Agent Central, votre plateforme de référence pour une collection croissante d'agents IA spécialisés qui sont conçus pour simplifier vos tâches quotidiennes. Notre interface intuitive vous aide à trouver rapidement le bon agent, vous permettant de travailler plus intelligemment et d'accomplir davantage. Explorez nos fonctionalités et débloquez de nouveaux niveaux d'efficacité et d'innovation dans votre vie professionnelle."
                    this.ComingSoontext = "Prochainement!"
                    break;
                  case "ES":
                    this.language = 'es'
                    this.lblPageTitle = "Explorar Agentes de IA";
                    this.lblPopularAgents = "Agentes Populares"
                    this.lblAllAgents = "Todos los Agentes"
                    this.lblPageDescriptionPart1 ="Bienvenido a Agent Central, tu plataforma de referencia para una colección creciente de agentes de IA especializados diseñados para agilizar tus tareas diarias. Nuestra interfaz intuitiva te ayuda a encontrar rápidamente el agente adecuado, permitiéndote trabajar de manera más inteligente y lograr más. Explora nuestra biblioteca y desbloquea nuevos niveles de eficiencia e innovación en tu vida profesional."
                    this.ComingSoontext = "¡Próximamente!"
                    break;

                  default:
                    this.language = 'en'
                    this.lblPageTitle = "Explore AI Agents";
                    this.lblPopularAgents = "Popular Agents"
                    this.lblAllAgents = "All Agents"
                    this.lblPageDescriptionPart1 ="Welcome to Agent Central, your go-to platform for a growing collection of specialized AI agents designed to streamline your daily tasks. Our intuitive interface helps you quickly find the right agent, empowering you to work smarter and achieve more. Explore our library and unlock new levels of efficiency and innovation in your professional life."
                    this.ComingSoontext = "Coming Soon!"
                    break;
                }
              });
          }        
 
    ngOnInit(): void {
        this.verifyDVoiceUser();
        this.appComponentService.setIsNewChatVisible(false);
        this.agentService.getAgents().subscribe((result: Agent[])=>{
            this.allAgents = result;
        });
        this.manageLocale();
        this.loadingService.setLoadingState(false);
        this.loadingService.setstopstreaming(false);
        const path: string = window.location.pathname; 
        var botName = path.replace("/", "");
        if(botName == "agent-central"){
            const apps = ["efs", "crm", "billing"];
            apps.forEach(app => {
                this.botResponseService.verifyBotUser(app).subscribe((response: any) => {
                    if (response.result == "Failure") {
                        if (app === "efs") {
                            this.appComponentService.setIsEFSSUser(false);
                            this.agentService.updateEFS();
                        } else if (app === "crm") {
                            this.appComponentService.setIsCRMUser(false);
                            this.agentService.updateCrmBot();
                        } else if (app === "billing") {
                            this.appComponentService.setIsBillingUser(false);
                            this.agentService.updateBillingBot();
                        }
                        // this.agentService.updateEFS();
                    } 
                    else {
                        if (app === "efs") {
                            this.appComponentService.setIsEFSSUser(true);
                        } else if (app === "crm") {
                            this.appComponentService.setIsCRMUser(true);
                        } else if (app === "billing") {
                            this.appComponentService.setIsBillingUser(true);
                        }
                    }
                }
                )
                })
        }
        else{           
        this.botResponseService.verifyBotUser(botName).subscribe((response: any) => {
            if (response.result == "Failure") {
              this.appComponentService.setIsEFSSUser(false);
              this.agentService.updateEFS()
              
            }
            else 
              this.appComponentService.setIsEFSSUser(true);
            
          })
        }
    }
  
    updateDisplayedAgents(): Agent[] {
        return this.filteredAgents.slice(this.currentIndex, this.currentIndex + 6); 
    }
 
    prevAgents() {
        if (this.currentIndex > 0) {
            this.currentIndex -= 6;
        }
    }
 
    nextAgents() {
        if (this.currentIndex + 6 < this.filteredAgents.length) {
            this.currentIndex += 6; 
        }
    }

    navigateTo(agent: Agent){
        
        
        // 
        if(!agent.disabled){
            this.router.navigate([agent.path]);
        }
    }

    verifyDVoiceUser() {
        const path: string = window.location.pathname; 
        const dVoicePath: string = "/deloitte-voice";
        this.chatservice.verifyDVoiceUser().subscribe((response: UserAccessResponse ) => {
          this.appComponentService.setIsDVoiceUser(response.hasAccess);
          this.agentService.updateDeloitteVoiceAgent();
          if(!response.hasAccess){
            if(path === dVoicePath || path.startsWith(`${dVoicePath}/`)){
              this.router.navigate(['/error']);
            }
          }
        });
    
      }

    

}