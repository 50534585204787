<form *ngIf="currentStage === deloitteVoiceStage.STARTED" [formGroup]="form" (ngSubmit)="process()">
   <div class="form-container">
      <div class="form-group-container">
         <div class="form-label-container">
            <h2>{{lblToolTips}}</h2>
            <p class="tip-description">{{lblToolTipsDesc}}</p>
         </div>
      </div>
      <div class="form-group-container">
         <div class="form-label-container">
            <h3>{{lblContentFormat}}</h3>
            <p>{{lblContentFormatDesc}}</p>
         </div>
         <app-select-dropdown
         formControlName="contentFormat"
         [options]="dropdown"
         placeholder="Select an option"
         (valueChange)="onFormatChange($event)">
         </app-select-dropdown>      
      </div>
      <div *ngIf="contentFormat === 'M'" class="form-group-container">
         <div class="form-label-container">
            <h3>{{lblContentRevision}}</h3>
            <p>{{lblContentRevisionDesc}}</p>
         </div>
         <textarea class="content-input" formControlName="manualInput" placeholder={{lblEnterContentHere}} maxlength="3000000"></textarea>
      </div>
      <div *ngIf="contentFormat === 'F'" class="form-group-container">
         <div class="file-upload-container">
            <app-file-upload
            class="file-upload-control"
            formControlName="files"
            [maxFiles]="maxFiles"
            [maxTotalSize]="maxFileSizeInMB * 1024 * 1024"
            [maxFileSize]="maxFileSizeInMB  * 1024 * 1024"
            [acceptedTypes]="[ 'docx', 'pdf']"
            [uploadLimitMessage] = "lblUploadLimitMessage"
          ></app-file-upload>
         </div>
      </div>
       <div class="form-group-container">
         <div class="form-label-container">
            <h3>{{lblAdditionalInstructions}}</h3>
            <p>{{lblAdditionalInstructionsDesc}}</p>
         </div>
         <textarea class="instructions-input" formControlName="additionalInstructions" placeholder={{lblEnterAdditionalInstructions}}></textarea>
      </div>
      <div class="button-container">
         <button type="submit" class="start-button" [disabled]="!isFormValid()">{{lblStartProcessing}}</button>
         <button type="button" class="reset-button" (click)="reset()">{{lblReset}}</button>
      </div>
   </div>
</form>

<app-deloitte-voice-processing 
  *ngIf="currentStage === deloitteVoiceStage.PROCESSING" 
  [contentFormat]="contentFormat"
  [inputFileName]="inputFileName"
  [lblContentBeingRevised]="lblContentBeingRevised">
</app-deloitte-voice-processing>

<app-deloitte-voice-completed 
  *ngIf="currentStage === deloitteVoiceStage.COMPLETED"
  [outputFileName]="outputFileName"
  [lblFileReadyToDownload]="lblFileReadyToDownload"
  (downloadFailed)="handleDownloadFailed()">
</app-deloitte-voice-completed>

<app-deloitte-voice-error 
  *ngIf="currentStage === deloitteVoiceStage.FAILED"
  (goBackEvent)="goBack()">
</app-deloitte-voice-error>
 