import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownOption } from 'src/app/componenets/select-dropdown/select-dropdown.component';
import { DeloitteVoiceStage } from '../deloitte-voice-stage.enum';
import { TaskStatus as DeloitteVoiceTaskStatus } from "../task-status.enum";
import { AppComponentService } from 'src/app/Services/appcomponentservice';
import { Subject, Observable, Subscription, takeUntil, switchMap, interval, takeWhile, tap, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { LoadingService } from 'src/app/Services/LoadingService';
import { DeloitteVoiceService } from 'src/app/Services/deloitte-voice.service';
import { DeloitteVoiceReviseRequest } from 'src/app/Services/interfaces/deloitte-voice-revise-request.interface';
import { DeloitteVoiceFileTrackerResponseDto } from 'src/app/Services/interfaces/deloitte-voice-file-tracker-response.interface';
import { DeloitteVoiceReviseResponse } from 'src/app/Services/interfaces/deloitte-voice-revise-response.interface';
import { ResetChatService } from 'src/app/Services/reset-chat.service';

@Component({
  selector: 'app-deloitte-voice-revise',
  templateUrl: './deloitte-voice-revise.component.html',
  styleUrls: ['./deloitte-voice-revise.component.css']
})
export class DeloitteVoiceReviseComponent implements OnInit, OnDestroy {
  private resetSubscription: Subscription;
  dropdown: DropdownOption[] = [
    {label: {en: "Manual input", fr: "Saisie manuelle", es: "Entrada manual"}, value: "M"},
    {label: {en: "File upload", fr: "Téléchargement de fichier", es: "Subida de archivo"}, value: "F"}
  ];
  private destroy = new Subject<void>();
  form: FormGroup;
  uploadedFile: File | null = null;
  contentFormat: string = "M";
  currentStage: DeloitteVoiceStage = DeloitteVoiceStage.STARTED;
  deloitteVoiceStage: typeof DeloitteVoiceStage = DeloitteVoiceStage;
  newTaskSubscription: Subscription;
  inputFileName: string;
  outputFileName: string;
  lblToolTips: string;
  lblToolTipsDesc: string;
  lblContentFormat: string;
  lblContentFormatDesc: string;
  lblContentRevision: string;
  lblContentRevisionDesc: string;
  lblAdditionalInstructions: string;
  lblAdditionalInstructionsDesc: string;
  lblStartProcessing: string;
  lblReset: string;
  lblContentBeingRevised: string;
  lblFileReadyToDownload: string;
  lblEnterContentHere: string;
  lblEnterAdditionalInstructions: string;
  // Polling
  private pollingSubscription: Subscription;

  lblUploadLimitMessage: string;

  maxFiles: number = 1;
  maxFileSizeInMB: number = 3; 

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public appComponentService: AppComponentService,
    private deloitteVoiceService: DeloitteVoiceService,
    public loadingService: LoadingService,
    private i18n: I18nService,
    private resetChatService: ResetChatService
    
  ) {
    this.form = this.fb.group({
      contentFormat: [this.contentFormat, Validators.required],
      files: [[]],
      manualInput: ['', [Validators.required, Validators.maxLength(3000000)]],
      additionalInstructions:  ['', [Validators.maxLength(3000000)]]
    });
  }


  ngOnDestroy(): void {
    this.newTaskSubscription.unsubscribe();
    this.stopPolling();
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.loadingService.setLoadingState(false);
    }
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblToolTips = "Conseils sur les outils:";
            this.lblToolTipsDesc = "Toutes les directives de marque de l'entreprise sont déjà intégrées à Deloitte Voice. Il vous suffit de fournir votre texte, et l'outil l'ajustera pour assurer sa conformité. Utilisez le champ optionnel d'instructions supplémentaires pour préciser des directives non couvertes par les normes de l'entreprise (ex. : ton et voix, mots-clés à utiliser ou à éviter, contexte additionnel du texte fourni, etc.).";
            this.lblContentFormat = "Format du contenu";
            this.lblContentFormatDesc = "Veuillez choisir le format pour télécharger votre contenu.";
            this.lblContentRevision = "Saisie du contenu pour révision";
            this.lblContentRevisionDesc = "Veuillez entrer ou coller le contenu à réviser.";
            this.lblAdditionalInstructions = "Instructions supplémentaires facultatives";
            this.lblAdditionalInstructionsDesc = "Fournissez toutes instructions supplémentaires qui pourraient aider à clarifier vos exigences.";
            this.lblStartProcessing = "Commencer le traitement";
            this.lblReset = "Réinitialiser";
            this.lblContentBeingRevised = "Votre contenu est en cours de révision. Cela peut prendre jusqu'à 15 minutes. Une fois terminé, un lien de téléchargement de fichier vous sera fourni, vous permettant de récupérer le contenu généré conforme au guide de style de l'entreprise. Merci de votre patience.";
            this.lblFileReadyToDownload = "Votre fichier est prêt à être téléchargé ! Merci de votre patience. Cliquez sur la carte du fichier ci-dessus pour télécharger votre document révisé.";
            this.lblEnterContentHere = "Entrez le contenu ici...";
            this.lblEnterAdditionalInstructions = "Entrez les instructions supplémentaires ice..."
            this.lblUploadLimitMessage = `Vous pouvez télécharger un document d'une taille maximale de ${this.maxFileSizeInMB}MB Mo.`;
            break;
          case "ES":
            this.lblToolTips = "Consejos de Herramientas:";
            this.lblToolTipsDesc = "Todas las directrices de marca de la empresa ya están integradas en Deloitte Voice. Simplemente proporciona tu copia, y la herramienta la alineará y revisará para el cumplimiento. Usa el campo de entrada de instrucciones adicionales opcional para proporcionar más instrucciones no cubiertas por las directrices estándar de la empresa (por ejemplo, tono y voz, palabras clave a usar o evitar, contexto adicional sobre la copia proporcionada, etc.).";
            this.lblContentFormat = "Formato de Contenido";
            this.lblContentFormatDesc = "Por favor, elige el formato para subir tu contenido.";
            this.lblContentRevision = "Entrada de Contenido para Revisión";
            this.lblContentRevisionDesc = "Por favor, ingresa o pega el contenido que necesita ser revisado.";
            this.lblAdditionalInstructions = "Instrucciones Adicionales Opcionales";
            this.lblAdditionalInstructionsDesc = "Proporciona cualquier instrucción extra que pueda ayudar a aclarar tus requisitos.";
            this.lblStartProcessing = "Iniciar Procesamiento";
            this.lblReset = "Restablecer";
            this.lblContentBeingRevised = "Tu contenido está siendo revisado. Esto puede tardar hasta 15 minutos. Una vez terminado, se proporcionará un enlace de descarga del archivo, donde podrás recuperar el contenido generado que cumple con la guía de estilo de la empresa. Gracias por tu paciencia.";
            this.lblFileReadyToDownload = "¡Tu archivo está listo para descargar! Gracias por tu paciencia. Por favor, haz clic en la tarjeta del archivo arriba para descargar tu documento revisado.";
            this.lblEnterContentHere = "Ingresa el contenido aquí...";
            this.lblEnterAdditionalInstructions = "Ingresa las instrucciones adicionales aquí...";
            this.lblUploadLimitMessage = `Puede cargar un documento de hasta ${this.maxFileSizeInMB}MB de tamaño.`;
            break;
          default:
            this.lblToolTips = "Tool Tips:";
            this.lblToolTipsDesc = "All company branding guidelines are already built into Deloitte Voice. Simply provide your copy, and the tool will align and revise it for compliance. Use the optional additional instructional input field to provide further instructions not covered by standard company guidelines (e.g., tone and voice, keywords to use or avoid, additional context about the copy provided, etc.).";
            this.lblContentFormat = "Content Format";
            this.lblContentFormatDesc = "Please choose the format for uploading your content.";
            this.lblContentRevision = "Content Input for Revision";
            this.lblContentRevisionDesc = "Please enter or paste the content that needs to be revised.";
            this.lblAdditionalInstructions = "Optional Additional Instructions";
            this.lblAdditionalInstructionsDesc = "Provide any extra instructions that may help clarify your requirements.";
            this.lblStartProcessing = "Start Processing";
            this.lblReset = "Reset";
            this.lblContentBeingRevised = "Your content is being revised. This can take up to 15 minutes. Once finished, a file download link will be provided, where you can retrieve the generated content adhering to the company's style guide. Thank you for your patience.";
            this.lblFileReadyToDownload = "Your file is ready for download! Thank you for your patience. Please click the file card above to download your revised document.";
            this.lblEnterContentHere = "Enter the content here...";
            this.lblEnterAdditionalInstructions = "Enter the additional instructions here...";
            this.lblUploadLimitMessage = `You can upload a document up to ${this.maxFileSizeInMB}MB in size.`;
            break;
        }
      });
  }

  ngOnInit(): void {
    this.manageLocale();
    this.newTaskSubscription = this.appComponentService.newTask$.subscribe(() => {
      this.router.navigate([`/deloitte-voice`]);
    });
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      if (this.currentStage !== DeloitteVoiceStage.PROCESSING) {
        this.router.navigate([`/deloitte-voice`]);
      }
    });
  }

  onFormatChange(value: any) {
    this.contentFormat = value;
  }

  onFileChange(file: File | null) {
    this.uploadedFile = file;
  }



  reset() {
    this.form.reset();
    this.uploadedFile = null;
    this.contentFormat = 'M';
    this.form.patchValue({ contentFormat: 'M' , files: [] });
  }

  removeFile(file: File) {
    const currentFiles = this.form.get('files')?.value || [];
    const updatedFiles = currentFiles.filter((f: File) => f.name !== file.name);
    this.form.patchValue({ files: updatedFiles });
    this.uploadedFile = updatedFiles.length ? updatedFiles[0] : null;
}

  clearFiles() {
    this.form.patchValue({ files: [] });
  }

  isFormValid(): boolean {
    const contentFormat = this.form.get('contentFormat')?.value;
    const files = this.form.get('files')?.value || [];
    const manualInput = this.form.get('manualInput')?.value || '';
    const additionalInstructionsControl = this.form.get('additionalInstructions');
    const isAdditionalInstructionsValid = additionalInstructionsControl?.valid ?? true;

    if (contentFormat === 'F') {
      return files.length > 0 && isAdditionalInstructionsValid;
    } else {
      return manualInput.trim().length > 0 && isAdditionalInstructionsValid;
    }
  }

  process(): void {
    this.outputFileName = "";
    this.appComponentService.disableNewTaskButton();
    this.currentStage = DeloitteVoiceStage.PROCESSING;
    this.loadingService.setLoadingState(true);

    if (this.contentFormat === 'F') {
      this.uploadDocument().subscribe({
        next: response => {
          this.processDocuments().subscribe({
            next: processResponse => {
              this.postProcessSuccess(processResponse);
            },
            error: error => {
              this.handleProcessError(error);
            }
          });
        },
        error: error => {
          this.handleProcessError(error);
        }
      });
    } else {
      this.processDocuments().subscribe({
        next: response => {
          this.postProcessSuccess(response);
        },
        error: error => {
          this.handleProcessError(error);
        }
      });
    }
  }

  private uploadDocument(): Observable<any> {
    const files: File[] = this.form.get('files')?.value || [];
    const formData = new FormData();
    files.forEach(item => {
      formData.append('files', item, item.name);
    });
    formData.append('container', "");
    formData.append('folderName', "");
    this.inputFileName = files[0]?.name;

    return this.deloitteVoiceService.uploadDocument(formData);
  }


  private processDocuments(): Observable<any> {
    const { manualInput = "", contentFormat = "", additionalInstructions ='', files = [] } = this.form.value
    const fileName = files?.[0]?.name ?? "";
    const request: DeloitteVoiceReviseRequest = {
      contentFormat: contentFormat,
      contentInput: manualInput,
      additionalInstructions: additionalInstructions,
      fileName
    };

    return this.deloitteVoiceService.reviseDocument(request);
  }


  private postProcessSuccess(response: DeloitteVoiceReviseResponse): void {
    this.startPolling(response.taskId);
  }

  private postPolling(fileName: string): void {
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
    this.outputFileName = fileName;
    this.currentStage = DeloitteVoiceStage.COMPLETED;
  }

  private handleProcessError(error: any): void {
    this.currentStage = DeloitteVoiceStage.FAILED;
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();

  }

  goBack():void{
    this.currentStage = DeloitteVoiceStage.STARTED;
  }

  

  startPolling(taskId: string): void {
    this.pollingSubscription = interval(30000) // Poll every 30 seconds
      .pipe(
        switchMap(() =>
          this.deloitteVoiceService.getStatus(taskId).pipe(
            catchError(error => {
              this.stopPolling();
              this.handleProcessError(error);
              return throwError(error);
            })
          )
        ),
        tap((response : DeloitteVoiceFileTrackerResponseDto) => {
          if (response.status === DeloitteVoiceTaskStatus.Completed) {
            this.postPolling(response.outputFileName);
            this.stopPolling();
          } else if (response.status === DeloitteVoiceTaskStatus.Failed) {
            this.handleProcessError(response);
            this.stopPolling();
          }
        }),
        takeWhile((response : DeloitteVoiceFileTrackerResponseDto) =>
          response.status === DeloitteVoiceTaskStatus.Pending ||
        response.status === DeloitteVoiceTaskStatus.InProgress)
      )
      .subscribe(
        response => {
        },
        error => {
          console.error('Polling error in subscribe:', error);
        }
      );
  }

  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  handleDownloadFailed(): void {
    this.currentStage = DeloitteVoiceStage.FAILED;
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
  }




}