import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedText } from 'src/app/model/agent.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  
  @Input() message: string = '';
  @Input() maxFiles: number = 1;
  @Input() maxTotalSize: number = 10 * 1024 * 1024; // 10MB default
  @Input() maxFileSize: number = 2 * 1024 * 1024;   // 2MB default
  @Input() acceptedTypes: string[] = ['pdf', 'docx', 'txt'];
  @Input() uploadLimitMessage: string;
  @Input() additionalMessage: string = null;


  private destroy = new Subject<void>();
  // Labels
  lblDragHere: string;
  lblSupportedFiles: string;
  lblUploadMore: string;
  locale: string;

  constructor(private i18n: I18nService) {}


  files: File[] = [];
  errors: LocalizedText[] = [];
  isDragOver: boolean = false;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  ngOnInit() {
    this.manageLocale();
    if (!this.files) {
      this.files = [];
    }
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.locale = 'fr';
            this.lblDragHere = "Glissez ici ou cliquez pour télécharger";
            this.lblSupportedFiles = `Format de fichier pris en charge: ${this.acceptedTypes.join(', ').toUpperCase()}`;
            this.lblUploadMore = "Télécharger plus";
            break;

          case "ES":
            this.locale = 'es';
            this.lblDragHere = "Arrastre aquí o haga clic para cargar";
            this.lblSupportedFiles = `Formato de archivo compatible: ${this.acceptedTypes.join(', ').toUpperCase()}`;
            this.lblUploadMore = "Subir más";
            break;
        
        default:
            this.locale = 'en';
            this.lblUploadMore = "Upload More";
            this.lblDragHere = "Drag Here or click to upload";
            this.lblSupportedFiles = `Supported file format: ${this.acceptedTypes.join(', ').toUpperCase()}`;
            break;
        }
      });
  }

  get acceptedFileTypesString(): string {
    return this.acceptedTypes.map(type => `.${type}`).join(',');
  }

  displayAcceptedTypes(): string {
    return this.acceptedTypes.map(type => type.toUpperCase()).join(', ');
  }

  get showUploadMoreButton(): boolean {
    return this.files.length > 0 && 
           !this.isMaxFilesReached() && 
           this.maxFiles > 1;
  }

  openFileInput(): void {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.click();
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    
    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
  }

  onFileSelected(event: Event): void {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (files) {
      this.handleFiles(Array.from(files));
    }
    // Reset input value to allow selecting the same file again
    element.value = '';
  }

  
  handleFiles(newFiles: File[]): void {
    this.errors = [];
  
    // For single file upload, replace existing file
    if (this.maxFiles < 2) {
      this.files = [];
    }
  
    // Validate file types
    const invalidFiles = newFiles.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase() || '';
      return !this.acceptedTypes.includes(extension);
    });
  
    if (invalidFiles.length > 0) {
      this.errors.push({
        en: `Invalid file type. Supported formats: ${this.displayAcceptedTypes()}`,
        fr: `Type de fichier invalide. Formats pris en charge: ${this.displayAcceptedTypes()}`,
        es: `Tipo de archivo no válido. Formatos compatibles: ${this.displayAcceptedTypes()}`
      });
      return;
    }
  
    // Validate individual file sizes first
    const validFiles: File[] = [];
    newFiles.forEach(file => {
      if (file.size === 0) {
        this.errors.push({
          en: `${file.name}: File size cannot be 0 B`,
          fr: `${file.name}: La taille du fichier ne peut pas être de 0 B`,
          es: `${file.name}: El tamaño del archivo no puede ser 0 B`
        });
      } else if (file.size > this.maxFileSize) {
        this.errors.push({
          en: `${file.name}: File size cannot exceed ${this.formatFileSize(this.maxFileSize)}`,
          fr: `${file.name}: La taille du fichier ne peut pas dépasser ${this.formatFileSize(this.maxFileSize)}`,
          es: `${file.name}: El tamaño del archivo no puede exceder ${this.formatFileSize(this.maxFileSize)}`
        });
      } else {
        validFiles.push(file);
      }
    });
  
    if (validFiles.length === 0) return; // Stop if no valid files remain
  
    // Check for duplicate files
    const duplicateFiles = validFiles
      .filter(file => this.files.some(existingFile => existingFile.name.toLowerCase() === file.name.toLowerCase()));
    if (duplicateFiles.length > 0) {
      this.errors.push({
        en: `Duplicate files are not allowed: ${duplicateFiles.map(file => file.name).join(', ')}`,
        fr: `Les fichiers en double ne sont pas autorisés: ${duplicateFiles.map(file => file.name).join(', ')}`,
        es: `No se permiten archivos duplicados: ${duplicateFiles.map(file => file.name).join(', ')}`
      });
      return;
    }
  
    // Check if adding new files would exceed maxFiles limit
    if (this.files.length + validFiles.length > this.maxFiles) {
      this.errors.push({
        en: `Maximum ${this.maxFiles} files allowed`,
        fr: `Maximum de ${this.maxFiles} fichiers autorisés`,
        es: `Máximo ${this.maxFiles} archivos permitidos`
      });
      return;
    }
  
    // Calculate total size including existing files
    const currentTotalSize = this.files.reduce((sum, file) => sum + file.size, 0);
    const newTotalSize = validFiles.reduce((sum, file) => sum + file.size, 0) + currentTotalSize;
  
    if (newTotalSize > this.maxTotalSize) {
      this.errors.push({
        en: `Total size cannot exceed ${this.formatFileSize(this.maxTotalSize)}`,
        fr: `La taille totale ne peut pas dépasser ${this.formatFileSize(this.maxTotalSize)}`,
        es: `El tamaño total no puede exceder los ${this.formatFileSize(this.maxTotalSize)}`
      });
      return;
    }
  
    // Append valid files and trigger change
    this.files = [...this.files, ...validFiles];
    this.onChange(this.files);
    this.onTouch();
  }
  

  removeFile(index: number): void {
    if (index >= 0 && index < this.files.length) {
      this.files.splice(index, 1);
      this.files = [...this.files];
      // Clear errors when removing files
      this.errors = [];
      this.onChange(this.files);
      this.onTouch();
    }
  }

  isMaxFilesReached(): boolean {
    return this.files.length >= this.maxFiles;
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  // ControlValueAccessor implementation
  writeValue(files: File[]): void {
    if (files) {
      this.files = files;
    } else {
      this.files = [];
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }
}