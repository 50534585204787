<form *ngIf="currentStage === deloitteVoiceStage.STARTED" [formGroup]="form" (ngSubmit)="process()">

    <div class="form-container">
        <div class="form-group-container">
            <div class="form-label-container">
               <h2>{{lblToolTips}}</h2>
               <p class="tip-description">{{lblToolTipsDesc}}</p>
            </div>
        </div>

        <div class="form-group-container">
            <div class="form-label-container">
            <h3>{{lblContentTopicBriefing}}</h3>
            <p>{{lblContentTopicBriefingDesc}}</p>
            </div>
            <textarea class="content-input" formControlName="contentTopicBriefing" placeholder={{lblBrieflyDescribe}} maxlength="3000000"></textarea>   
        </div>

        <div class="form-group-container-dropdownInRow">
            <div class="form-item">
                <div class="form-label-container">
                    <h3>{{lblTargetAudience}}</h3>
                    <p>{{lblTargetAudienceDesc}}</p>
                </div>
                <div class="split-container">
                    <app-select-dropdown
                        class="app-dropdown-Medium"
                        formControlName="targetAudience"
                        [options]="dropdownTA"
                        placeholder={{lblSelectTA}}
                        (valueChange)="onTargetAudienceChange($event)">
                    </app-select-dropdown>
                    <div class="dropdown-other-container" *ngIf="form.get('targetAudience').value === OTHER_VALUE">
                        <input type="text" formControlName="targetAudienceTextbox" placeholder={{lblPleaseSpecify}} class="other-details-textbox">
                    </div>
                </div>       
            </div>
            <div class="form-item">                
                <div class="form-label-container">
                    <h3>{{lblOverallStyle}}</h3>
                    <p>{{lblOverallStyleDesc}}</p>
                </div>
                <app-select-dropdown
                    formControlName="overallStyle"
                    [options]="dropdownOS"
                    placeholder=""
                    (valueChange)="onOverallStyleChange($event)">
                </app-select-dropdown> 
            </div>
        </div>

        <div class="form-group-container-dropdownInRow">
            <div class="form-item">
                <div class="form-label-container">
                    <h3>{{lblContentLength}}</h3>
                    <p>{{lblContentLengthDesc}}</p>
                </div>
                <app-select-dropdown
                    formControlName="contentLength"
                    [options]="dropdownCL"
                    placeholder=""
                    (valueChange)="onContentLengthChange($event)">
                </app-select-dropdown>
            </div>
            <div class="form-item">                
                <div class="form-label-container">
                    <h3>{{lblContentMedium}}</h3>
                    <p>{{lblContentMediumDesc}}</p>
                </div>
                <div class="split-container">
                    <app-select-dropdown
                        class="app-dropdown-Medium"
                        formControlName="contentMedium"
                        [options]="dropdownCM"
                        placeholder=""
                        (valueChange)="onContentMedium($event)">
                    </app-select-dropdown>
                    <div class="dropdown-other-container" *ngIf="form.get('contentMedium').value === OTHER_VALUE">
                        <input type="text" formControlName="contentMediumTextbox" placeholder={{lblPleaseSpecify}} class="other-details-textbox"/>
                    </div>
                </div>                 
            </div>
        </div>

        <div class="form-group-container">
            <div class="form-label-container">
                <h3>{{lblOptionalReferenceFile}}</h3>
                <p>{{lblOptionalReferenceFileDesc}}</p>
            </div>

            <div class="file-upload-container" *ngIf="!uploadedFile">
                <app-file-upload
                class="file-upload-control"
                formControlName="files"
                [maxFiles]="maxFiles"
                [maxTotalSize]="maxTotalSizeInMB * 1024 * 1024"
                [maxFileSize]="maxTotalSizeInMB * 1024 * 1024"
                [acceptedTypes]="['pdf', 'docx', 'txt']"
                [uploadLimitMessage] = "lblUploadLimitMessage"
                [additionalMessage] = "lblAdditionalUploadLimitMessage"
              ></app-file-upload>
            </div>

            <div class="form-group-container-additional-details">
                <div class="form-label-container">
                    <h3 class="additional-details-label">{{lblAdditionalDetails}}</h3>
                    <div class="additional-details-spacing">
                        <p class="additional-details-text">{{lblAdditionalDetailsTitle}}</p>
                        <input type="text" formControlName="additionalDetailsTitle" class="additional-details-custom-input" maxlength="200"/>
                    </div>
                    <div class="additional-details-spacing">
                        <p class="additional-details-text">{{lblAdditionalDetailsKeywordsInclude}}</p>
                        <input type="text" formControlName="additionalDetailsKeywordsInclude" class="additional-details-custom-input" maxlength="200"/>
                    </div>
                    <div class="additional-details-spacing">
                        <p class="additional-details-text">{{lblAdditionalDetailsKeywordsExclude}}</p>
                        <input type="text" formControlName="additionalDetailsKeywordsExclude" class="additional-details-custom-input" maxlength="200"/>
                    </div>
                </div>       
            </div>
        </div>

        <div class="button-container">
            <button type="submit" class="start-button" [disabled]="!isFormValid()">{{lblStartProcessing}}</button>
            <button type="button" class="reset-button" (click)="reset()">{{lblReset}}</button>
        </div>

    </div>
</form>

<app-deloitte-voice-processing
    *ngIf="currentStage === deloitteVoiceStage.PROCESSING"
    [inputFileName]="inputFileName"
    [lblContentBeingRevised]="lblContentBeingRevised">
</app-deloitte-voice-processing>
   
<app-deloitte-voice-completed
    *ngIf="currentStage === deloitteVoiceStage.COMPLETED"
    [outputFileName]="outputFileName"
    [lblFileReadyToDownload]="lblFileReadyToDownload"
    (downloadFailed)="handleDownloadFailed()">
</app-deloitte-voice-completed>

<app-deloitte-voice-error
    *ngIf="currentStage === deloitteVoiceStage.FAILED"
    (goBackEvent)="goBack()">
</app-deloitte-voice-error>