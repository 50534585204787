import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, concatMap, delay, of, takeUntil } from 'rxjs';
import { ChatService } from '../Services/chat.service';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from '../Services/LoadingService';
import { AppComponentService } from '../Services/appcomponentservice';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentUploadService } from '../Services/document-upload.service';
import { FeedbackDisLikeComponent } from '../feedback-dis-like/feedback-dis-like.component';
import { FeedbackLikeComponent } from '../feedback-like/feedback-like.component';
import { HttpClient } from '@angular/common/http';
import { Themes, Size, WidthState, ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { ResetBlobStorageService } from '../Services/reset-blob-storage.service';
import { ResetChatService } from '../Services/reset-chat.service';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { Router } from '@angular/router';
import { marked } from 'marked';
import { MarkdownService } from 'ngx-markdown';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Component({
  selector: 'app-doc-analyzer',
  templateUrl: './document-analyzer.component.html',
  styleUrls: ['./document-analyzer.component.css']
})
export class DocumentAnalyzerComponent {
  // New props
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  private resetSubscription: Subscription;
  // Conditional
  isProcessingCompleted: boolean = false;
  selectedFiles: File[] = [];
  isUploadLimitExceeded: boolean = false; // Flag for upload limit exceeded
  MAX_FILE_SIZE = 50 * 1024 * 1024; // Max total file size in bytes (50 MB)
  MAX_FILE_COUNT = 5; // Max number of files
  cancelButtonKind = ButtonKind.secondaryLoud;
  primaryButtonTheme = Themes.green;
  primaryButtonKind = ButtonKind.primaryLoud;
  allowedTypes: string[] = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/VTT', 'text/vtt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/html'
  ];
  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  private destroy = new Subject<void>();
  lblNameGreeting: string;
  lblHelpGreeting: string;
  lblHiName: string;
  txtUploadDocs: string;
  lblDragHere: string;
  txtYouCanUpload: string;
  lblStartProcessing: string;
  txtReachedUploadLimit: string;
  btnStartProcessing: string;
  btnReset: string;
  lblAskFirstQues: string;

  userName: any;
  feedbacklike: any;
  userQuery: string;
  isCopy: boolean;
  userResponse: string;
  isStreaming: boolean;
  dragDropElement: any;
  dragDropEnabled: any;
  FileNames: string[] = [];
  errorCount: any;
  gotSummaryResp: boolean;
  disableProcessing: boolean = false;
  errorMesg: any;
  references: any;
  docerrorMessage: boolean = false;
  ErrorMessage: string;
  docStatusMessage: any;
  StatusMessage: any;
  StatusMessageUpload: string;
  StatusMessageProcess: string;
  StatusMessagePrepare: string;
  loadmsgprc: any;
  loadmsgEmb: any;
  loadmsgSumry: any;
  resetDisabled: boolean = true;
  displayName: any;
  lblSorrySomething2: string;
  lblSorrySomething1: string;
  lblUserCommunity: string;
  copyIndex: any;
  typesToUpload: string;
  constructor(private modalService: ModalService, private chatService: ChatService, public loadingService: LoadingService,
    public appcomponentservice: AppComponentService, private render: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef,
    private https: HttpClient, private DocumentUploadService: DocumentUploadService, private i18n: I18nService,
    private ResetBlobStorageService: ResetBlobStorageService, private resetChatService: ResetChatService,
    private recentAgentsService: RecentAgentsService,
    private router: Router, private markdownService: MarkdownService) {

  }

  isLoading = false;
  disableSendButton = true;
  isDefaultText: boolean;
  userPrompt: string;
  systemModel = "GPT 3.5"
  systemMessage: string;
  responseStyle: string;
  guid = uuidv4()
  userId: string;
  regenerateFlag = false;
  userQueryLength;
  likedIndex: number = -1;
  dislikedIndex: number = -1;
  isLiked: boolean = false;
  isDisLiked: boolean = false;
  likedIndices: Set<number> = new Set();
  dislikedIndices: Set<number> = new Set();
  disabledLikeIndices = new Set<number>();
  disabledDisLikeIndices = new Set<number>();
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('dynamicTextarea') dynamicTextarea!: ElementRef;
  textareaRef: any;
  messages = new BehaviorSubject<{ user: string; text: string }[]>([
  ]);
  messages$ = this.messages.asObservable();
  private defaultHeight: number = 50.5;

  autoScrollEnabled: boolean = true;
  lastScrollTop: number = 0;
  showScrollToBottom: boolean = false;

  // New methods for file upload

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }
  onFileSelected(event: any) {
    const validFiles = this.getValidFiles(event.target.files);
    if (validFiles) {
      const nonDuplicateFiles = validFiles.filter((file: File) =>
        !this.selectedFiles.some((selectedFile: File) => selectedFile.name === file.name)
      );
      if (nonDuplicateFiles.length > 0) {
        this.appendValidFiles(this.selectedFiles, nonDuplicateFiles);
        this.updateUploadLimitFlag();
        this.gotSummaryResp = false;
      }
    }
    if (this.selectedFiles.length > 0) {
      this.isDefaultText = false;
      this.disableProcessing = false;
      this.appcomponentservice.setIsSendButtonDisabled(false);
      this.loadingService.setLoadingState(false);
      this.messages.next([]);
      this.resetDisabled = true;
    }
  }
  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const validFiles = this.getValidFiles(event.dataTransfer.files)
    if (validFiles) {
      const nonDuplicateFiles = validFiles.filter((file: File) =>
        !this.selectedFiles.some((selectedFile: File) => selectedFile.name === file.name)
      );
      if (nonDuplicateFiles.length > 0) {
        this.appendValidFiles(this.selectedFiles, nonDuplicateFiles);
        this.updateUploadLimitFlag();
        this.gotSummaryResp = false;
        this.fileInput.nativeElement.value = '';
      }
    } if (this.selectedFiles.length > 0) {

      this.isDefaultText = false;
      this.disableProcessing = false;
      this.appcomponentservice.setIsSendButtonDisabled(false);
      this.loadingService.setLoadingState(false);
      this.messages.next([]);
      this.resetDisabled = true;
    }

  }

  removeFile(file: File): void {
    this.selectedFiles = this.selectedFiles.filter(f => f !== file);
    this.updateUploadLimitFlag();
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  triggerFileUpload2() {
    this.fileInput2.nativeElement.click();
  }

  onScroll(event: Event): void {
    const container = this.chatContainer.nativeElement;
    const currentScrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // Check if user is scrolling down or up
    if (currentScrollTop < this.lastScrollTop) {
      this.autoScrollEnabled = false;
    }

    // Determine if the user is at the bottom
    const atBottom = scrollHeight - clientHeight <= currentScrollTop + 15;

    // Update the flag to show/hide the Scroll to Bottom button
    this.showScrollToBottom = !atBottom;

    if (atBottom) {
      this.autoScrollEnabled = true;
    }

    // Update the last scroll position
    this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For negative scrolling
  }

  OnScrollToBottom() {
    setTimeout(() => {
      this.autoScrollEnabled = true;
    }, 590);
    this.scrollToBottomSlow();
  }

  scrollToBottomSlow() {
    const container = this.chatContainer.nativeElement;
    const target = container.scrollHeight; // Total scrollable height
    const start = container.scrollTop; // Current scroll position
    const distance = target - start; // Distance to scroll
    const duration = 600; // Duration of the scroll in milliseconds

    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime; // Initialize start time
      const timeElapsed = currentTime - startTime; // Time elapsed since start
      const progress = Math.min(timeElapsed / duration, 1); // Normalize progress (0 to 1)

      // Easing function for smooth scrolling
      const ease = easeInOutQuad(progress);
      container.scrollTop = start + distance * ease; // Update scroll position

      if (timeElapsed < duration) {
        requestAnimationFrame(animation); // Continue the animation
      } else {
        this.showScrollToBottom = false; // Hide the button after scrolling
      }
    };

    requestAnimationFrame(animation); // Start the animation

    // Easing function: ease in-out
    function easeInOutQuad(t: number) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
  }
  openFeedbackDislikeModel(index, botRes) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isDisLiked = true;
    this.dislikedIndex = index;
    this.likedIndex = -1;
    this.dislikedIndices.add(index);
    this.likedIndices.delete(index);
    this.disabledDisLikeIndices.add(index);
    var FeedbackDisLike = {
      convid: this.guid,
      userid: this.userId,
      name: this.userName,
      userQuery: this.userQuery,
      apiResponse: botRes,
      gptModel: this.appcomponentservice.systemModel,
      isDocAnalyzerActive: true,
      document_Name: this.selectedFiles.map(file => file.name),
    };
    this.modalService.open(FeedbackDisLikeComponent, { lstoptionDislike: FeedbackDisLike, }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }

  doLiked(index: number): boolean {
    return this.likedIndices.has(index);
  }
  doDisliked(index: number): boolean {
    return this.dislikedIndices.has(index);
  }

  openFeedbackLikeModel(index: number, botRes: string) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isLiked = true;
    this.likedIndex = index;
    this.dislikedIndex = -1;
    this.likedIndices.add(index);
    this.dislikedIndices.delete(index);
    this.disabledLikeIndices.add(index);
    var FeedbackLike = {
      convid: this.guid,
      userid: this.userId,
      name: this.userName,
      userQuery: this.userQuery,
      apiResponse: botRes,
      gptModel: this.appcomponentservice.systemModel,
      isDocAnalyzerActive: true,
      document_Name: this.selectedFiles.map(file => file.name),
    };

    this.modalService.open(FeedbackLikeComponent, { lstlikeOptions: FeedbackLike }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
      // const likeButton = document.querySelector('.likeButton');
      //   if (likeButton) {
      //       likeButton.classList.add('green-like');
      //   }
      //   const dislikeButton = document.querySelector('.dislikeButton');
      // Check if the likeButton element exists
      // if (dislikeButton) {
      //     // Remove the likeButton element from the DOM
      //     dislikeButton.remove();
      // }
    });
  }
  resetDocuments() {
    this.resetVariables();
    this.autoScrollEnabled = true;
    this.isProcessingCompleted = false;

    if (!this.gotSummaryResp) {

    }
    else {
      if (this.selectedFiles.length != 0) {
        this.ResetBlobStorageService.resetUploadedDocument().subscribe(
          (response: any) => {
          },
          (error: any) => {
            console.log('File upload failed.', error);
          });
      }
    }
    this.selectedFiles = [];
    this.resetTextareaHeight();
    this.disableSendButton = true;
    this.isDefaultText = true;
    this.appcomponentservice.setIsSendButtonDisabled(false);
    this.loadingService.setLoadingState(false);
    this.messages.next([]);
    this.resetDisabled = true;
    this.docerrorMessage = false;
    this.userPrompt = "";
    this.fileInput.nativeElement.value = '';
    this.isProcessingCompleted = false;
  }
  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblNameGreeting = "Bonjour";
            this.lblHelpGreeting = "Comment puis-je vous aider?";
            this.lblHiName = "Bonjour, Pradeep";
            this.txtUploadDocs = "Téléchargez des documents et je les analyserai pour vous !";
            this.lblDragHere = "Glissez ici ou cliquez pour télécharger";
            this.txtYouCanUpload = "Vous pouvez télécharger jusqu'à 5 documents ou un total de 50 Mo";
            this.typesToUpload = "Format de fichier pris en charge : PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.lblStartProcessing = "Téléchargez vos fichiers et cliquez sur 'Démarrer le traitement'. Nous nous occuperons du prétraitement en arrière-plan pendant environ 3 à 5 minutes. Une fois terminé, le champ de saisie du chat sera déverrouillé et vous pourrez commencer à discuter avec votre document.";
            this.ErrorMessage = "Désolé, une erreur s'est produite. Veuillez réessayer ultérieurement."; //Gen-D Translations above 1 and below 3 too
            this.StatusMessageUpload = "Téléchargement des fichiers!";
            this.StatusMessageProcess = "Traitement des fichiers, veuillez patienter!";
            this.StatusMessagePrepare = "Préparation du résumé, veuillez patienter!";
            this.txtReachedUploadLimit = "Vous avez atteint votre limite de téléchargement.";
            this.btnStartProcessing = "Commencer le traitement";
            this.btnReset = "Réinitialiser";
            this.lblAskFirstQues = "Posez votre première question sur vos fichiers traités pour commencer";
            this.lblSorrySomething1 = "Oops, quelque chose ne va pas. Veuillez réessayer. Si le problème persiste, veuillez vous rendre sur la ";
            this.lblSorrySomething2 = " et publier votre problème dans le canal 'Discussion'. Notre équipe s'occupera de ce problème dès que possible.";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            break;
          case "ES":
            this.lblNameGreeting = "Hola";
            this.lblHelpGreeting = "¿Cómo puedo ayudarte hoy?";
            this.lblHiName = "Hola, Pradeep!";
            this.txtUploadDocs = "¡Sube Documentos y los analizaré por ti!";
            this.lblDragHere = "Arrastra Aquí o haz clic para subir ";
            this.txtYouCanUpload = "Puedes subir hasta 5 documentos o un total de 50MB";
            this.typesToUpload = "Formato de archivo soportados: PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.lblStartProcessing = "Sube tus archivos y haz clic en 'Iniciar Procesamiento'. Nos encargaremos del preprocesamiento en segundo plano durante unos 3-5 minutos. Una vez que esté listo, el campo de entrada del chat se desbloqueará y podrás comenzar a chatear con tu documento.";
            this.ErrorMessage = "Lo siento, algo salió mal. Por favor, inténtalo de nuevo más tarde.";
            this.StatusMessageUpload = "¡Subiendo los Archivos!";
            this.StatusMessageProcess = 'Procesando los Archivos, ¡Por favor espera!';
            this.StatusMessagePrepare = "Preparando el Resumen, ¡Por favor espera!";
            this.txtReachedUploadLimit = "Has alcanzado tu límite de carga.";
            this.btnStartProcessing = "Iniciar Procesamiento";
            this.btnReset = "Restablecer";
            this.lblAskFirstQues = "Haz tu primera pregunta sobre tus archivos procesados para comenzar";
            this.lblSorrySomething1 = "Ups, algo salió mal. Por favor, inténtalo de nuevo. Si el problema persiste, dirígete a la ";
            this.lblSorrySomething2 = " y publica tu problema en el canal 'Discusión'. Nuestro equipo atenderá el problema lo antes posible.";
            this.lblUserCommunity = "Comunidad de Usuarios";

            break;

          default:
            this.lblNameGreeting = "Hi";
            this.lblHelpGreeting = "How can I help you today?";
            this.lblHiName = "Hi, Pradeep!";
            this.txtUploadDocs = "Upload Documents and I’ll analyze it for you!";
            this.lblDragHere = "Drag Here or click to upload ";
            this.txtYouCanUpload = "You can upload up to 5 documents or 50MB total in size";
            this.typesToUpload = "Supported file format: PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.lblStartProcessing = "Upload your files and click 'Start Processing'. We'll handle the preprocessing in the background for about 3-5 minutes. Once it's done, the chat input field will be unlocked, and you can start chatting with your document.";
            this.ErrorMessage = "Sorry, something went wrong. Please try again later.";
            this.StatusMessageUpload = "Uploading the Files!";
            this.StatusMessageProcess = 'Processing the Files, Please wait!';
            this.StatusMessagePrepare = "Preparing the Summary, Please wait!";
            this.txtReachedUploadLimit = "You have reached your upload limit.";
            this.btnStartProcessing = "Start Processing";
            this.btnReset = "Reset";
            this.lblAskFirstQues = "Ask your first question about your Processed files to start";
            this.lblSorrySomething1 = "Oops, something is wrong. Please try again. If the issue persists, please head to the ";
            this.lblSorrySomething2 = " and post your issue in the 'Discussion' channel. Our team will attend the issue asap.";
            this.lblUserCommunity = "User Community";

            break;
        }
        // alert(locale)
      });
  }


  ngOnInit() {
    this.recentAgentsService.addRecentPath(this.router.url);
    this.appcomponentservice.setIsNewChatVisible(true);
    this.manageLocale();
    this.isDefaultText = true;
    this.isProcessingCompleted = false;
    this.appcomponentservice.setIsSendButtonDisabled(false);
    this.loadingService.setLoadingState(false); // Set loading to true
    // Subscribe to the service and call the function when the event is triggered
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      this.resetVariables();
    });
    this.appcomponentservice.triggerNewChatFunction$.subscribe(() => {
      this.newChat();
    });
    this.getProfile();
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.loadingService.setLoadingState(false);
    }
  }
  resetVariables() {
    this.likedIndex = -1;
    this.dislikedIndex = -1;
    this.isLiked = false;
    this.isDisLiked = false;
    this.likedIndices = new Set();
    this.dislikedIndices = new Set();
    this.disabledLikeIndices = new Set<number>();
    this.disabledDisLikeIndices = new Set<number>();
  }
  defaultPrompt(msg) {
    this.userPrompt = msg;
  }

  async startProcessing(): Promise<any> {
    this.resetDisabled = true;
    this.docStatusMessage = true;
    this.StatusMessage = this.StatusMessageUpload;
    this.disableProcessing = true;
    this.isStreaming = true;
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }
    this.appcomponentservice.setIsSendButtonDisabled(true);
    this.loadingService.setLoadingState(true);
    this.FileNames = [];
    const formData = new FormData();
    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(item => {
        console.log("this.item selected", item);
        formData.append('files', item, item.name);

      })
    }

    this.DocumentUploadService.uploadDocument(formData)
      .subscribe(
        (response: any) => {
          this.StatusMessage = this.StatusMessageProcess;
          this.DocumentUploadService.ProcessDocuments()
            .subscribe(
              async (response: any) => {
                this.StatusMessage = this.StatusMessagePrepare;
                this.DocumentUploadService.GenerateEmbeddings()
                  .subscribe(
                    (response: any) => {
                      this.DocumentUploadService.ShowSummary()
                        .subscribe(
                          async (response: any) => {
                            console.log("response.data", response.data)
                            this.gotSummaryResp = true;

                            //  if(response.data != []  )    {}

                            // Track the files that were processed
                            let processedFiles = new Set();

                            // Process the response data
                            if (response.data.length != 0) {
                              let i = 0;
                              while (i < response.data.length) {
                                const item = response.data[i];
                                const title = item.title;
                                const summary = item.summary;
                                this.docStatusMessage = false;
                                this.loadingService.setLoadingState(false); // Set loading to false

                                // Simulate streaming for the processed file
                                await this.simulateStreaming("**" + title + "**" + " : " + summary + '\n \n').then(() => {
                                  this.cdr.detectChanges();
                                  if (this.autoScrollEnabled) {
                                    this.scrollToBottom();
                                  }
                                  this.appcomponentservice.setIsSendButtonDisabled(false);
                                  this.isStreaming = false;
                                });
                                processedFiles.add(title);
                                i++;
                              }
                            }
                            if (this.selectedFiles.length == 0) {
                              this.docStatusMessage = false;
                              this.loadingService.setLoadingState(false); // Set loading to false
                              await this.simulateStreaming("I apologize for the inconvenience, but I am unable to process the documents at the moment.").then(() => {                              //this.customizeMarkdownTag();
                                this.cdr.detectChanges();
                                if (this.autoScrollEnabled) {
                                  this.scrollToBottom();
                                }
                                this.appcomponentservice.setIsSendButtonDisabled(false);
                                this.isStreaming = false;
                              });
                            }
                            // For files not included in the response
                            let j = 0;
                            while (j < this.selectedFiles.length) {
                              const fileItem = this.selectedFiles[j];
                              if (!processedFiles.has(fileItem.name)) {
                                this.docStatusMessage = false;
                                this.loadingService.setLoadingState(false); // Set loading to false
                                await this.simulateStreaming("**" + fileItem.name + "** : I apologize for the inconvenience, but I am unable to process this file. \n \n").then(() => {
                                  this.cdr.detectChanges();
                                  if (this.autoScrollEnabled) {
                                    this.scrollToBottom();
                                  }
                                  this.appcomponentservice.setIsSendButtonDisabled(false);
                                  this.isStreaming = false;
                                });
                              }
                              j++;
                            }
                            this.gotSummaryResp = true;
                            this.isProcessingCompleted = true;
                            this.disableSendButton = false;
                            this.resetDisabled = false;

                          },
                          (error: any) => {
                            this.docStatusMessage = false;
                            this.errorCount = this.errorCount + 1;
                            this.appcomponentservice.setIsSendButtonDisabled(false);
                            this.loadingService.setLoadingState(false); // Set loading to false
                            this.docerrorMessage = true;
                            this.resetDisabled = false;
                            this.isProcessingCompleted = true;

                          });
                    },
                    (error: any) => {
                      this.docStatusMessage = false;
                      this.errorCount = this.errorCount + 1;
                      this.appcomponentservice.setIsSendButtonDisabled(false);
                      this.loadingService.setLoadingState(false); // Set loading to false
                      this.docerrorMessage = true;
                      this.resetDisabled = false;
                      this.isProcessingCompleted = true;

                    });
              },
              (error: any) => {
                this.docStatusMessage = false;
                this.errorCount = this.errorCount + 1;
                this.appcomponentservice.setIsSendButtonDisabled(false);
                this.loadingService.setLoadingState(false);
                this.docerrorMessage = true;
                this.resetDisabled = false;
                this.isProcessingCompleted = true;

              });
        },

        (error: any) => {
          this.docStatusMessage = false;
          this.errorCount = this.errorCount + 1;
          this.appcomponentservice.setIsSendButtonDisabled(false);
          this.loadingService.setLoadingState(false);
          this.docerrorMessage = true;
          this.isProcessingCompleted = true;

        },
      );
  }


  async sendMessage(regenerateFlag: boolean, responsetext: any) {
    var tempPrompt = this.userPrompt.trim();
    this.userQuery = this.userPrompt.trim();

    if (tempPrompt == undefined || tempPrompt.trim() == "") {
      return;
    } else {
      this.resetDisabled = true;
      this.isStreaming = true;
      this.disableSendButton = true;
      this.isDefaultText = false;
      this.userPrompt = "";
      this.resetTextareaHeight();
      if (this.autoScrollEnabled) {
        this.scrollToBottom();
      }
      this.appcomponentservice.setIsSendButtonDisabled(true);
      this.loadingService.setLoadingState(true); // Set loading to true
      this.messages.next([...this.messages.value, { user: 'user', text: tempPrompt }]);
    }

    this.chatService.getDocumentResult(tempPrompt.trim()).subscribe(async (response) => {
      console.log("response.content", response);

      // Parse markdown to HTML
      //let parsedHtml: string;

      if (response.output) {

        this.loadingService.setLoadingState(false); // Set loading to false
        var parsedText = this.markdownService.parse(response.output);

        var customizedContent = this.customizeMarkDown(parsedText).then(async (res) => {
          let fullMessage = res;

          //Add references if available
          if (response.references && response.references.length > 0) {
            this.references = response.references.map((reference: any) => ` ${reference}`);
            fullMessage += "\n\n **References:** \n\n" + this.references.join('<br>');
          }
          await this.simulateStreaming(fullMessage).then(() => {
            this.bindActionToCopyIcon();
            this.cdr.detectChanges(); // Ensure DOM updates are detected (optional)
            if (this.autoScrollEnabled) {
              this.scrollToBottom();
            }
            this.disableSendButton = false;
            this.appcomponentservice.setIsSendButtonDisabled(false);
            this.isStreaming = false;
            this.resetDisabled = false;
          });
        });
      }
    });
  }

  async customizeMarkDown(htmlContent): Promise<any> {
    // Parse the HTML string into a DOM
    const parser = new DOMParser();
    const resolvedHtmlContent = await (typeof htmlContent === 'string' ? htmlContent : await htmlContent);
    const doc = parser.parseFromString(resolvedHtmlContent, 'text/html');
    //const preTagRegex = /<pre.*?>(.*?)<\/pre>/gs;  // Regular expression to match <pre> tags
    const preTagRegex = /<pre[^>]*>.*?<\/pre>/gs;
    const preTags = [...htmlContent.matchAll(preTagRegex)];

    // Step 1: Customize <a> tags using regex
    const aTagRegex = /<a\b([^>]*)href="([^"]+)"([^>]*)>/gi;
    htmlContent = htmlContent.replace(aTagRegex, (match, beforeHref, href, afterHref) => {
      const hasTarget = /target\s*=\s*"_blank"/i.test(match);
      const hasRel = /rel\s*=\s*".*?noopener.*?"/i.test(match);

      let newAttributes = `${beforeHref}href="${href}"${afterHref}`;
      if (!hasTarget) {
        newAttributes += ` target="_blank"`;
      }
      // if (!hasRel) {
      //   newAttributes += ` rel="noopener noreferrer"`;
      // }

      return `<a${newAttributes}>`;
    });


    // Iterate through each <pre> match and insert the <div> tag based on its index
    let updatedHtmlContent = htmlContent;

    preTags.forEach((match, index) => {
      const preContent = match[0]; // Full <pre> tag content
      const codeTagClassRegex = /<code class="([^"]+)"/; // Regex to extract class from <code>
      const codeClassMatch = preContent.match(codeTagClassRegex);
      const codeClass = codeClassMatch ? codeClassMatch[1] : "";
      const classSplit = codeClass.split('-');
      if (codeClass !== null && codeClass !== undefined && codeClass !== '') {
        const langName =
          classSplit.length > 1 &&
            classSplit[1] &&
            classSplit[1] !== 'none' &&
            classSplit[1] !== 'undefined'
            ? classSplit[1]
            : 'Plain Text';

        // Insert the new <div> tag
        const newDivTag = `<div class="preTagHeader">
                          <span class="clsLanguage text-left">${langName}</span>
                          <a class="clsCopy text-right"><i class="fa fa-clone"></i></a>
                        </div>`;
        updatedHtmlContent = updatedHtmlContent.replace(match[0], (preMatch) => {
          // Insert the new <div> tag between <pre> and <code> tags
          return preMatch.replace('<pre>', `<pre>${newDivTag}`);
        });
      }
    });
    return updatedHtmlContent;
  }
  bindActionToCopyIcon() {
    setTimeout(() => {
      const preTags = this.el.nativeElement.querySelectorAll('pre');
      preTags.forEach((preTag: HTMLElement, idx: any) => {
        // Only add the header if it doesn't already exist
        const existingHeader = preTag.querySelector('.preTagHeader');
        if (existingHeader) {
          const copyTag = preTag.querySelector('.clsCopy');
          if (copyTag) {
            const copyIconTag = preTag.querySelector('.copyIconCustomized');
            if (!copyIconTag) {
              var copyFavIcon = copyTag.querySelector('i');
              if (copyFavIcon)
                this.render.addClass(copyFavIcon, 'clsCopyIcon' + idx)
              this.render.addClass(copyTag, 'copyIconCustomized');
              this.render.listen(copyTag, 'click', () => this.copyCode(preTag, idx));
            }
          }
        }
      });
    }, 100);
  }
  async simulateStreaming(message: string): Promise<void> {
    return new Promise((resolve) => {
      const chunkSize = 10; // Adjust chunk size as needed
      let index = 0;

      const addChunk = () => {
        if (index < message.length) {
          const isBotMessage =
            this.messages.value.length > 0 &&
            this.messages.value[this.messages.value.length - 1].user === 'bot';

          this.messages.next(
            isBotMessage
              ? [
                ...this.messages.value.slice(0, -1),
                {
                  ...this.messages.value[this.messages.value.length - 1],
                  text:
                    this.messages.value[this.messages.value.length - 1].text +
                    message.substring(index, index + chunkSize),
                },
              ]
              : [
                ...this.messages.value,
                {
                  user: 'bot',
                  text: message.substring(index, index + chunkSize),
                },
              ]
          );

          if (this.autoScrollEnabled) {
            this.scrollToBottom();
          }

          index += chunkSize;

          of(null)
            .pipe(
              delay(50), // Adjust delay as needed
              concatMap(() => of(null))
            )
            .subscribe(addChunk);
        } else {
          resolve();
        }
      };

      addChunk();
    });
  }

  setUserMessage(text: string) {
    this.userResponse = text;
  }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }

  copyCode(preTag: HTMLElement, idx: number) {
    var codeTag = preTag.querySelector('code');
    if (codeTag != null) {
      const codeText = codeTag.innerText; // Extract the code text
      const textArea = document.createElement('textarea');
      textArea.value = codeText;
      textArea.style.position = 'fixed';  // Avoid page scroll
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999); // For mobile devices
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);

      var copyIcon = document.querySelector('.clsCopyIcon' + idx) as HTMLElement;
      if (copyIcon) {
        copyIcon.classList.remove('fa-clone');
        copyIcon.classList.add('fa-check');
        copyIcon.classList.add('icontick');
        setTimeout(() => {
          copyIcon.classList.remove('fa-check');
          copyIcon.classList.remove('icontick');
          copyIcon.classList.add('fa-clone');
        }, 5000);
      }
    }
  }


  copyToClipboard(text: any, index: any) {
    this.isCopy = true;
    this.copyIndex = index;
    
    // Convert back to plain text (if needed)
    text = text.replace(/<div class="preTagHeader">.*?<\/div>/gs, '\n');

    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const plainText = doc.body.innerHTML || ""; //doc.body.textContent || "";
    let innerText = plainText;

    // Remove all HTML tags
    innerText = innerText.replace(/<[^>]*>/g, '');

    // Remove all occurrences of "**" and "`" (if needed)
    innerText = innerText.replace(/(\*\*|`)/g, '');

    // Find the position of the word "References"
    const referencesIndex = innerText.indexOf('References');
    if (referencesIndex !== -1) {
      // Split the text into two parts: before and after "References"
      const beforeReferences = innerText.substring(0, referencesIndex + 'References'.length);
      const afterReferences = innerText.substring(referencesIndex + 'References'.length);
      // Ensure "References:" ends with a colon and a newline
      const formattedBeforeReferences = beforeReferences.trim() + ':';

      // Handle the part after "References"
      let cleanedAfterReferences;
      if (afterReferences.includes('<br>')) {
        // Replace <br> tags with newlines
        cleanedAfterReferences = afterReferences.replace(/<br\s*\/?>/gi, '\n');
      } else {
        // Use regex to split after file extensions
        const fileExtensionRegex = /(\.\w+)(?=\s|$)/g; // Matches file extensions like .docx, .pdf, etc.
        cleanedAfterReferences = afterReferences
          .split(fileExtensionRegex) // Split by file extensions
          .reduce((acc, part, index, array) => {
            if (part.match(fileExtensionRegex)) {
              // If the part is a file extension, combine it with the previous part
              acc[acc.length - 1] += part;
            } else if (index < array.length - 1) {
              // Otherwise, treat it as a new file name
              acc.push(part.trim());
            }
            return acc;
          }, [])
          .join('\n'); // Join with newlines
      }

      // Combine the two parts back together
      innerText = beforeReferences + cleanedAfterReferences;
    }
    navigator.clipboard.writeText(innerText).then(() => {
      console.log('Message copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
    setTimeout(() => {
      this.isCopy = false;
    }, 3000);
  }

  isAtBottom(container: HTMLElement): boolean {
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const currentScrollTop = container.scrollTop;

    // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
    return scrollHeight - clientHeight <= currentScrollTop + 15;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    if (!textarea) return;

    const atBottom = this.isAtBottom(this.chatContainer.nativeElement);

    // Reset height to 'auto' so that the textarea can shrink
    textarea.style.height = 'auto';

    // Calculate the new height based on the content's scrollHeight
    const maxHeight = 100; // Max height for the textarea
    const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height

    // Set the height to the new height
    textarea.style.height = `${newHeight}px`;

    if (atBottom) {
      this.scrollToBottom();
    }
  }
  updateChatContainerHeight(messageBoxHeight: number): void {
    const wrapperHeight = window.innerHeight;
    const chatContainerHeight = wrapperHeight - messageBoxHeight - 50; // Adjusting for any extra space like margins or padding
    this.render.setStyle(this.chatContainer.nativeElement, 'height', `${chatContainerHeight}px`);
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        const chatContainer = this.chatContainer.nativeElement as HTMLElement;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }

  }
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.userName = profile.displayName;
        this.userId = profile.mail;
        this.displayName = profile.givenName;
      })
  }

  // Handle Enter and Shift + Enter key events
  // handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
  //   if (event.key === 'Enter' && !event.shiftKey) {
  //     // If Enter is pressed without Shift, call sendMessage function
  //     if (this.disableSendButton)

  //     this.sendMessage(false, "");
  //     event.preventDefault(); // Prevent the default action of adding a newline
  //     return;
  //   }
  //   // If Shift + Enter is pressed, allow the textarea to insert a new line
  // }

  handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      // If Enter is pressed without Shift, call sendMessage function
      if (this.userPrompt.trim() === "" || this.disableSendButton) {
        event.preventDefault(); // Prevent the default action of adding a newline
        return;
      }
      this.sendMessage(false, "");
      event.preventDefault(); // Prevent the default action of adding a newline
    }

  }

  newChat() {
    if (this.appcomponentservice.isSendButtonDisabled || this.loadingService.isLoading) {
      return;
    }
    this.resetDocuments();

    this.isProcessingCompleted = false;

  }

  private isValidFile(file: File): boolean {
    return (file.type === "") ? file.name.includes('.vtt') : this.allowedTypes.includes(file.type);
  }

  // Method to get valid files from a FileList
  getValidFiles(fileList: FileList): File[] {
    const filesArray = Array.from(fileList); // Convert FileList to an array
    return filesArray.filter(file => this.isValidFile(file));
  }

  // Method to append valid files from one array to another
  appendValidFiles(toArray: File[], fromArray: File[]) {
    const validFiles = fromArray.filter(file => this.isValidFile(file));

    for (let i = 0; i < validFiles.length; i++) {
      toArray.push(validFiles[i]);
    }
  }

  private updateUploadLimitFlag(): void {
    const totalSize = this.getTotalSelectedFilesSize();
    // Check if file count exceeds the maximum limit
    if (this.selectedFiles.length > this.MAX_FILE_COUNT || totalSize > this.MAX_FILE_SIZE) {
      this.isUploadLimitExceeded = true; // Set the flag if exceeded
    } else {
      this.isUploadLimitExceeded = false; // Reset the flag if within limits
    }
  }

  // Helper method to get the total size of already selected files
  getTotalSelectedFilesSize(): number {
    return this.selectedFiles.reduce((sum, file) => sum + file.size, 0);
  }

  ngAfterViewInit() {
    const textarea = this.dynamicTextarea.nativeElement;
    // Ensure it starts with the correct height
    textarea.style.height = `${this.defaultHeight}px`;
  }

  resetTextareaHeight(): void {
    const textarea = this.dynamicTextarea.nativeElement;
    if (!textarea) return;

    // Reset height to the default height
    textarea.style.height = `${this.defaultHeight}px`;
  }

}
