import { Component, Input, ElementRef, HostListener, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { FeedbackService } from '../Services/feedback.service';
import { HttpClient } from '@angular/common/http';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-feedback-like',
  templateUrl: './feedback-like.component.html',
  styleUrls: ['./feedback-like.component.css']
})

export class FeedbackLikeComponent implements OnInit, OnDestroy, AfterViewInit {
  txtFeedback: any;
  feedbackRes: any = "";
  feedbackLike: boolean = false;
  private destroy = new Subject<void>();
  selectedLanguage: string = 'EN';
  lblFeedback: string;
  lblLikedFeedbackInParticular: string;
  lblSubmit: string;
  
  // Flag to track if we should ignore the next click (useful when opening modal)
  private ignoreNextClick: boolean = true;

  updateCharacterCount() { }

  constructor(
    public modalService: ModalService,
    private apiService: FeedbackService, 
    private http: HttpClient, 
    private i18n: I18nService, 
    public appcomponentService: AppComponentService,
    private elementRef: ElementRef
  ) { }

  @Input() lstlikeOptions: any;
  @Input() FeedbackLike: any = [];
  @Input() isFooter: boolean;
  @Input() size: string;
  @Input() isInverse: boolean;

  // Host listener to detect clicks outside the component
  @HostListener('document:mousedown', ['$event'])
  clickOutside(event: MouseEvent) {
    // If we should ignore this click, reset the flag and return
    if (this.ignoreNextClick) {
      this.ignoreNextClick = false;
      return;
    }
    
    // Check if the click was outside of this component
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.modalService.close();
    }
  }

  // Prevent clicks inside the modal from closing it
  @HostListener('mousedown', ['$event'])
  clickInside(event: Event) {
    // Mark this click as being inside the component
    event.stopPropagation();
  }
  
  // Set up after the view is initialized
  ngAfterViewInit() {
    // Add a small delay to ignore the initial click that might have opened the modal
    setTimeout(() => {
      this.ignoreNextClick = false;
    }, 100);
  }

  close() {
    var model = {
      "feedback": "positive",
      "feedbackType": "positive",
      "document_Name": this.lstlikeOptions.documentNames || [],
      "userQuery": this.lstlikeOptions.userQuery,
      "apiResponse": this.lstlikeOptions.apiResponse,
      "gptModel": this.lstlikeOptions.gptModel,
      "conversationId": this.lstlikeOptions.convid,
      "selectedOption": []
    }
    var Botmodel = {
      "selectedOption": [],
      "apiResponse": this.lstlikeOptions.apiResponse,
      "userQuery": this.lstlikeOptions.userQuery,
      "feedback":"positive",
      "feedbackType": "positive",
      
    }
    console.log("Feedback model", model);

    if (this.lstlikeOptions.isBotActive) {
      
      // if(this.lstlikeOptions.isEFSActive){
      console.log("submitting EFS Feedback model", Botmodel);
      // console.log("Document names being submitted:", model.document_Name);
      // this.apiService.submitEFSFeedbackData(model)
      this.apiService.submitBotFeedbackData(Botmodel)
        .subscribe(res => {
          console.log("EFS Feedback response", res);


          this.modalService.close();
        },
          (err) => {
            console.log("EFS Feedback error", err);
            console.log("Full error response:", JSON.stringify(err, null, 2));
            console.log("Error details:", err.error);
            this.modalService.close();
          }
        );
    }

    else{this.apiService.submitFeedbackData(model)
      .subscribe(res => {
        console.log("Feedback response", res);
        this.modalService.close();
      },
        (err) => {
          this.modalService.close();
        });
    this.feedbackLike = true;
    console.log("like res", model);
  }}

  Submit() {
    console.log("lstlikeOptions", this.lstlikeOptions)
    var feedRes = (this.feedbackRes == null) || (this.feedbackRes.trim() == "") ? 'positive' : this.feedbackRes;
    
    var userQuery = this.lstlikeOptions.userQuery;

    if (!userQuery || userQuery.trim() === '') {
      userQuery = "summary";
    }

    var model = {
      "feedback": feedRes.replace('\n', ' '),
      "feedbackType": "positive",
      "document_Name": this.lstlikeOptions.document_Name || [],
      "userQuery": userQuery,
      "apiResponse": this.lstlikeOptions.apiResponse,
      "gptModel": this.lstlikeOptions.gptModel,
      "conversationId": this.lstlikeOptions.convid,
      "selectedOption": []
    }

    var Botmodel = {
      "selectedOption": [],
      "apiResponse": this.lstlikeOptions.apiResponse,
      "userQuery": this.lstlikeOptions.userQuery,
      "feedback": feedRes.replace('\n', ' '),
      "feedbackType": "positive",
      "botAppName" : this.lstlikeOptions.botAppName
    }

    console.log("Feedback model before submission:", model);

    if (this.lstlikeOptions.isDocAnalyzerActive) {
      
      // if(this.lstlikeOptions.isDocAnalyzerActive){
      console.log("submitting Doc Analyzer Feedback model", model);
      console.log("Document names being submitted:", model.document_Name);
      this.apiService.submitDocFeedbackData(model)
        .subscribe(res => {
          console.log("Doc Analyzer Feedback response", res);


          this.modalService.close();
        },
          (err) => {
            console.log("Doc Analyzer Feedback error", err);
            console.log("Full error response:", JSON.stringify(err, null, 2));
            console.log("Error details:", err.error);
            this.modalService.close();
          }
        );
    }

    else if (this.lstlikeOptions.isBotActive) {
      
      // if(this.lstlikeOptions.isEFSActive){
      console.log("submitting EFS Feedback model", Botmodel);
      // console.log("Document names being submitted:", model.document_Name);
      // this.apiService.submitEFSFeedbackData(model)
      this.apiService.submitBotFeedbackData(Botmodel)
        .subscribe(res => {
          console.log("EFS Feedback response", res);


          this.modalService.close();
        },
          (err) => {
            console.log("EFS Feedback error", err);
            console.log("Full error response:", JSON.stringify(err, null, 2));
            console.log("Error details:", err.error);
            this.modalService.close();
          }
        );
    }

    else {
      console.log("Submitting general feedback");
      this.apiService.submitFeedbackData(model)
        .subscribe(res => {
          console.log("Feedback response", res);
          this.modalService.close();
        },
          (err) => {

            this.modalService.close();
          });
      this.feedbackLike = true;
      console.log("like res", model);
    }
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblFeedback = "Commentaires";
            this.lblLikedFeedbackInParticular = "Qu'avez-vous aimé dans cette réponse en particulier ?";
            this.lblSubmit = "Soumettre";
            break;
          case "ES":
            this.lblFeedback = "Comentarios";
            this.lblLikedFeedbackInParticular = "¿Qué te gustó de esta respuesta en particular?";
            this.lblSubmit = "Enviar";
            break;
          default:
            this.lblFeedback = "Feedback";
            this.lblLikedFeedbackInParticular = "What did you like about this response in particular?";
            this.lblSubmit = "Submit";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit() {
    //centering feedback like modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentService.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentService.setIsPinChecked(true);
      }
      else {
        this.appcomponentService.setIsPinChecked(false);
      }
    }
    this.manageLocale();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}