<div class="chat-main-container" id="chatMainContainer">
    <div *ngIf="appcomponentservice.isErrorMessg" class="error-message">
       {{lblSorrySomething1}} <span class="userCommunity" (click)="openUserCommunityLink()">
       {{lblUserCommunity}}</span>{{lblSorrySomething2}}
    </div>
    <div class="chat-input" id="chatinput">
       <textarea rows="1" placeholder={{lblAskQuestion}} [(ngModel)]='userPrompt'
       (input)="onInput()"   #dynamicTextarea
       (keydown)="handleKeydown($event, dynamicTextarea)" class="userinput"></textarea>
       <button type="submit" [ngClass]="{'disableSednButton':appcomponentservice.isSendButtonDisabled}" class="send-button" 
       (click)="sendMessage(false, userPrompt)" (keyup.enter)="sendMessage(false, userPrompt)" [disabled]="appcomponentservice.isSendButtonDisabled">
       <img   [ngClass]="{'send-button-disable':  appcomponentservice.isSendButtonDisabled}"  id="imgSendStyle" src="../assets/images/send_new.png" />
       </button>
    </div>
 </div>