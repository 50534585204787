import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class ResetBlobStorageService {
  user_id:  any;
  constructor(private httpClient: HttpClient) { }
  refresBlobUrl = protectedResources.resetUploadedDocument.endpoint;
resetUploadedDocument(): any {
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.get(  
    this.refresBlobUrl, {headers : headers}
   );
  }
}
