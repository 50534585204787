import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RecentAgentsService {
  private readonly MAX_RECENT_PATHS = 3;
  private recentPathsSubject = new BehaviorSubject<string[]>([
    '/document-analyzer'  
  ]);
  recentPaths$ = this.recentPathsSubject.asObservable();

  addRecentPath(path: string) {
    const currentPaths = this.recentPathsSubject.value;
    const filteredPaths = currentPaths.filter(p => p !== path);
    const newPaths = [path, ...filteredPaths].slice(0, this.MAX_RECENT_PATHS);
    this.recentPathsSubject.next(newPaths);
  }
}