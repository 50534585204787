<div *ngIf="files.length > 0" class="file-output-container">
    <div class="file-inner-container" *ngFor="let file of files; let i = index">
      <span class="file-name">{{ file.name }}</span>
      <img 
        src="../../../assets/images/cancel.png" 
        class="close-icon" 
        alt="Remove file" 
        (click)="removeFile(i)">
    </div>
  </div>
  