import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { protectedResources } from '../../auth-config';
 import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
@Injectable({
  providedIn: 'root'
})
export class ConHistoryService {

  constructor(private httpClient: HttpClient) { }
  apiurl = protectedResources.apiDBot.endpoint;
  getconversationId(): Observable<any>{
    return this.httpClient.get(this.apiurl + "OpenAPI/GetConversationIds");   
  }

  chatHistoryId(convId:any):  Observable<any>{
    return this.httpClient.get(this.apiurl + "OpenAPI/GetConversationHistory?ConvId="+convId);   
  }

  RenameLabel(convId:any, newLabel:any):  Observable<any>{
    return this.httpClient.post(this.apiurl + "OpenAPI/RenameLabel?ConvId="+convId+"&newLabel="+newLabel, {});   
  }

  deleteConvId(convId:any):  Observable<any>{
    return this.httpClient.delete(this.apiurl + "OpenAPI/DeleteConversation?ConvId="+convId);   
  }
}