import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

import { LabelPosition, Size, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';

import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { SharingmessageService } from '../Services/sharingmessage.service';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-systemsettings',
  templateUrl: './systemsettings.component.html',
  styleUrls: ['./systemsettings.component.css']
})
export class SystemsettingsComponent implements OnInit {
  private destroy = new Subject<void>();
  // Flag to prevent immediate closing on modal open
  private modalJustOpened = false;
  
  selectedLanguage: string = 'EN';
  lblChatSettings: string;
  btnClose: string;
  btnApply: string;
  lblPersonalityDesc: string;
  isSidebarPinned: boolean;

  constructor(
    public modalService: ModalService, 
    private i18n: I18nService, 
    private sharingmessageService: SharingmessageService,
    public appcomponentservice: AppComponentService,
    private elementRef: ElementRef
  ) { }
  
  @Input() isFooter: boolean;
  @Input() size: string;
  
  // Listen for global click events
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Skip if the modal just opened to prevent immediate closing
    if (this.modalJustOpened) {
      this.modalJustOpened = false;
      return;
    }

    // // Get modal element
    // const modalElement = this.elementRef.nativeElement.querySelector('.dds-modal');
    
    // // Check if element exists and click is outside the modal
    // if (modalElement && !modalElement.contains(event.target as Node)) {
    //   this.close();
    // }
  }

  // Listen for escape key press
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close();
  }
  
  close() {
    this.modalService.close();
  }

  onApply() {
    this.modalService.close(this.systemSettingsData);
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblChatSettings = "Paramètres de Chat";
            this.textareaOptions.placeholder = "Vous êtes un assistant amical et éloquent qui communique avec un ton à la fois décontracté et professionnel. Vous donnez des réponses claires et concises, sans avertissements inutiles ni répétitions. Vous êtes direct mais chaleureux, comme un ami averti qui explique quelque chose autour d'un café. Vous utilisez un langage naturel et des tournures conversationnelles, en évitant un discours trop formel ou robotique. Lorsque c'est pertinent, vous incluez de brefs exemples pour illustrer vos propos. Vous êtes prêt à échanger quelques plaisanteries légères tout en restant concentré sur votre objectif de rester utile. Vous n'avez pas tendance à vous excuser inutilement ni à utiliser des politesses excessives.";
            this.textareaOptions.label = "Instructions supplémentaires";
            this.personalitySelectOptions.label = "Personnalité";
            this.personalitySelectItems[0].heading = "Droit au but";
            this.personalitySelectItems[1].heading = "Soigné et professionnel";
            this.personalitySelectItems[2].heading = "Créatif et amusant";
            this.responseLengthSelectOptions.label = "Longueur";
            this.responseLengthSelectItems[0].heading = "Concise";
            this.responseLengthSelectItems[1].heading = "Équilibré";
            this.responseLengthSelectItems[2].heading = "Détaillé";
            this.btnClose = "Fermer";
            this.btnApply = "Appliquer";
            this.personalitySelectOptions.description= "La personnalité vous permet de sélectionner le style général avec lequel le chatbot interagit avec vous.";
            this.responseLengthSelectOptions.description = "La longueur vous permet de contrôler le niveau de détail des réponses du chatbot.";
            break;
          case "ES":
            this.lblChatSettings = "Configuración del Chat";
            this.textareaOptions.placeholder = "Eres un asistente amigable y articulado que se comunica en un tono casual pero profesional. Das respuestas claras y concisas sin advertencias innecesarias ni repeticiones. Eres directo pero cálido, como un amigo conocedor explicando cosas durante un café. Usas lenguaje natural y frases conversacionales, evitando un discurso excesivamente formal o robótico. Cuando es relevante, incluyes breves ejemplos para ilustrar tus puntos. Estás dispuesto a participar en una charla ligera mientras te mantienes enfocado en ser útil. No te disculpas innecesariamente ni usas cortesías excesivas.";
            this.textareaOptions.label = "Instrucciones Adicionales";
            this.personalitySelectOptions.label = "Personalidad";
            this.personalitySelectItems[0].heading = "Directo al Grano";
            this.personalitySelectItems[1].heading = "Pulido y Profesional";
            this.personalitySelectItems[2].heading = "Creativo y Divertido";
            this.responseLengthSelectOptions.label = "Longitud";
            this.responseLengthSelectItems[0].heading = "Conciso";
            this.responseLengthSelectItems[1].heading = "Equilibrado";
            this.responseLengthSelectItems[2].heading = "Elaborado";
            this.btnClose = "Restablecer";
            this.btnApply = "Aplicar";
            this.personalitySelectOptions.description= "La personalidad te permite seleccionar el estilo general en el que el chatbot interactúa contigo.";
            this.responseLengthSelectOptions.description = "La longitud te permite controlar cuán detalladas son las respuestas del chatbot.";
            break;
          default:
            this.lblChatSettings = "Chat Settings";
            this.textareaOptions.placeholder = "You are a friendly, articulate assistant who communicates in a casual yet professional tone. You give clear, concise responses without unnecessary disclaimers or repetition. You're direct but warm, like a knowledgeable friend explaining things over coffee. You use natural language and conversational phrases, avoiding overly formal or robotic speech. When relevant, you include brief examples to illustrate your points. You're willing to engage in light banter while staying focused on being helpful. You don't apologize unnecessarily or use excessive pleasantries.";
            this.textareaOptions.label = "Additional Instructions";
            this.personalitySelectOptions.label = "Personality";
            this.personalitySelectItems[0].heading = "Straight to the Point";
            this.personalitySelectItems[1].heading = "Polished and Professional";
            this.personalitySelectItems[2].heading = "Creative and Fun";
            this.responseLengthSelectOptions.label = "Length";
            this.responseLengthSelectItems[0].heading = "Concise";
            this.responseLengthSelectItems[1].heading = "Balanced";
            this.responseLengthSelectItems[2].heading = "Elaborate";
            this.btnClose = "Reset";
            this.btnApply = "Apply";
            this.personalitySelectOptions.description= "Personality allows you to select the overall style in which the chatbot interacts with you.";
            this.responseLengthSelectOptions.description = "Length enables you to control how detailed the chatbot's responses are.";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit() {
    // Set flag to prevent immediate closing
    this.modalJustOpened = true;
    
    // Reset flag after a short delay
    setTimeout(() => {
      this.modalJustOpened = false;
    }, 300);
    
    //centering chat settings modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
    
    this.manageLocale();
    if (this.appcomponentservice.systemSettings.systemMessage == undefined || this.appcomponentservice.systemSettings.systemMessage == null || this.appcomponentservice.systemSettings.systemMessage == "")
      this.systemSettingsData.systemprompt = '';
    else
      this.systemSettingsData.systemprompt = this.appcomponentservice.systemSettings.systemMessage;

    if (this.appcomponentservice.systemSettings.responseStyle == undefined || this.appcomponentservice.systemSettings.responseStyle == null || this.appcomponentservice.systemSettings.responseStyle == 0)
      this.systemSettingsData.personality = 0.5;
    else
      this.systemSettingsData.personality = this.appcomponentservice.systemSettings.responseStyle;

    if (this.appcomponentservice.systemSettings.userQueryLength == undefined || this.appcomponentservice.systemSettings.userQueryLength == null || this.appcomponentservice.systemSettings.userQueryLength == "")
      this.systemSettingsData.responselength = '-provide medium length and balanced response';
    else
      this.systemSettingsData.responselength = this.appcomponentservice.systemSettings.userQueryLength;
  }
  
  systemSettingsData: any = {
    systemprompt: '',
    personality: '',
    responselength: ''
  }
  
  textareaOptions: TextareaOptions = {
    label: 'Additional Instructions',
    labelPosition: LabelPosition.external,
    placeholder: "You are a friendly, articulate assistant who communicates in a casual yet professional tone. You give clear, concise responses without unnecessary disclaimers or repetition. You're direct but warm, like a knowledgeable friend explaining things over coffee. You use natural language and conversational phrases, avoiding overly formal or robotic speech. When relevant, you include brief examples to illustrate your points. You're willing to engage in light banter while staying focused on being helpful. You don't apologize unnecessarily or use excessive pleasantries.",
    minHeight: 250,
    isRequired: false,
    isError: false,
    errorState: ErrorState.default,
    description: '',
    customClass: '',
    errorMessage: '',
    maxLength: 300,
    maxLengthPosition: 'bottom-right',
    size: Size.sm,
    disabled: false
  };

  personalitySelectOptions: SelectOptions = {
    label: 'Personality',
    labelPosition: LabelPosition.external,
    description: 'Personality allows you to select the overall style in which the chatbot interacts with you.',
    placeholder: 'Professional',
    size: Size.md,
    disabled: false,
    isResponsive: false,
    isRequired: false,
    isError: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: ""
  };

  personalitySelectItems: SelectItemOptions[] = [
    {
      heading: "Deterministic",
      value: 0.1 //set to 0 instead of 0 as 0 is considered as empty in dropdown
    },
    {
      heading: "Professional",
      value: 0.5
    },
    {
      heading: "Creative",
      value: 1
    }
  ];

  responseLengthSelectOptions: SelectOptions = {
    label: 'Response Length',
    labelPosition: LabelPosition.external,
    description: "Length enables you to control how detailed the chatbot's responses are.",
    placeholder: '',
    size: Size.md,
    disabled: false,
    isResponsive: false,
    isRequired: false,
    isError: false,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: ""
  };

  responseLengthSelectItems: SelectItemOptions[] = [
    {
      heading: 'Concise',
      value: '-provide very short length and precise with your response'
    },
    {
      heading: 'Balanced',
      value: '-provide medium length and balanced response'
    },
    {
      heading: 'Elaborate',
      value: '-provide very long length and elaborate with your response'
    }
  ];
  
  reset() {
    this.systemSettingsData = {};
    setTimeout(() => {
      this.systemSettingsData = {
        systemprompt: '',
        personality: 0.5,
        responselength: '-provide medium length and balanced response'
      };
    }, 100);
  }
  
  setPersionality(event) {
    this.systemSettingsData.personality = event;
  }
  
  setResponseLength(event) {
    this.systemSettingsData.responselength = event;
  }
  
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}