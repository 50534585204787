import { Component, OnInit } from '@angular/core';
import { AppComponentService } from '../Services/appcomponentservice';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { Router } from '@angular/router';
import { ChatService, UserAccessResponse } from '../Services/chat.service';

@Component({
  selector: 'app-deloitte-voice',
  templateUrl: './deloitte-voice.component.html',
  styleUrl: './deloitte-voice.component.css'
})
export class DeloitteVoiceComponent implements OnInit {
  constructor(
    private recentAgentsService: RecentAgentsService,
    public appComponentService: AppComponentService, 
    private chatService: ChatService,
    private router: Router
  ) {
  }
  
  ngOnInit(): void {
    this.appComponentService.enableNewTaskButton();
    this.appComponentService.setIsNewChatVisible(false); 
    this.verifyDVoiceUser();
  }

    verifyDVoiceUser() {
      this.chatService.verifyDVoiceUser().subscribe((response: UserAccessResponse ) => {
        this.appComponentService.setIsDVoiceUser(response.hasAccess);
        if(response.hasAccess){
          const agentPath = "/deloitte-voice";
          this.recentAgentsService.addRecentPath(agentPath);        
        }
        else if (!response.hasAccess){
          
            this.router.navigate(['/error']);
         
        }
      });
    }
}
