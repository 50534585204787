import { Injectable } from '@angular/core';
import { valueAccessor } from '@usitsdasdesign/dds-ng/core/common-behaviors';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppComponentService {
    isLoading: boolean = false;
    isNewChatVisible: boolean = true;
    systemSettings: any;
    systemModel: string;
    isMainContentDisables: boolean = false; // Flag to disable the main content
    isModelOpen: boolean = false; // Flag to check if the model is open
    isPinChecked: boolean = false; // Flag to check if the pin is checked
    private triggerNewChatFunction = new Subject<void>();
    triggerNewChatFunction$ = this.triggerNewChatFunction.asObservable();
    isSendButtonDisabled: boolean = false; // Flag to disable the send button
    conversationData: any;
    private conversationDataSubject = new Subject<any>();
    conversationData$ = this.conversationDataSubject.asObservable();
    private triggerdeleteFunction = new Subject<void>();
    triggerdeleteFunction$ = this.triggerdeleteFunction.asObservable();
    isEFSUser: boolean = true;
    isDVoiceUser: boolean = true;
    isBillingUser: boolean = true;
    isCRMUser: boolean = true;
    // Props for New Task
    private newTaskSubject = new Subject<void>();
    newTask$ = this.newTaskSubject.asObservable();
    isNewTaskDisabled: boolean = true; 
    private triggerSendFunction = new Subject<SendMessageParams>();
    triggerSendFunction$ = this.triggerSendFunction.asObservable();
    isErrorMessg: boolean;
   
  
    SendMessageFunction(params: SendMessageParams) {
        this.triggerSendFunction.next(params);
    }
    setConversationId(data: any) {
      this.conversationData = data;
      this.conversationDataSubject.next(data);
    }
   
    setLoadingState(state: boolean) {
        this.isLoading = state;
    }
    setSystemSettings(obj: any) {
        this.systemSettings = obj;
        // set system settings
    }
    setSystemModel(model: string) {
        this.systemModel = model;
    }    
    // Method to trigger the function
    NewChatFunction() {
        this.triggerNewChatFunction.next();  // Emit the event
    }
    deleteconvFunc() {
        this.triggerdeleteFunction.next();  // Emit the event
    }
    // Method to set isModelOpen flag
    setIsModelOpen(value: boolean) {
        this.isModelOpen = value;
    }

    setIsPinChecked(value:boolean)  {
        this.isPinChecked = value;
    }
    setIsSendButtonDisabled(value: boolean) {
        this.isSendButtonDisabled = value;
    }
    setIsNewChatVisible(value: boolean): void {
        this.isNewChatVisible = value;
    }

    setIsEFSSUser(value: boolean): void {
        this.isEFSUser = value;
    }
    
    setIsDVoiceUser(value: boolean): void {
        this.isDVoiceUser = value;
    }
    setIsBillingUser(value: boolean): void {
        this.isBillingUser = value;
    }
  
    setIsCRMUser(value: boolean): void {
        this.isCRMUser = value;
    }
    setErrorMessage(value: boolean): void {
        this.isErrorMessg = value;
    }
    // Methods for New Task
    disableNewTaskButton() {
        this.isNewTaskDisabled = true;
    }

    enableNewTaskButton() {
        this.isNewTaskDisabled = false;
    }

    triggerNewTask() {
        this.newTaskSubject.next();
    }
}
interface SendMessageParams {
    regenrateflag: boolean;
    userPrompt: string;
  }
