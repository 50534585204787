<div class="feedbackdislikeModel" [ngClass]="{'middlefeedBackdislikeModelPopup': appcomponentService.isPinChecked}">
  <div class="dds-modal-overlay" (click)="close()"></div>
 
<div ddsResize class="dds-modal" [ngStyle]="{'margin-left': appcomponentService.isPinChecked ? '14.5rem' : '0rem'}">
    <div class="dds-modal__header headerMargin">
        <img class="supportAgentImage" src="../assets/images/support_agent.png" />
        <span class="dds-modal__title" class="txtFeedback">{{lblFeedback}}</span>
      
        <dds-button size="sm"
                  icon="dds-icon_close"
                  kind="silent"
                  theme="dark"
                  ariaLabel="Close modal"
                  class="dds-modal__close ddsBtnSelf"
                  [isInverse]="isInverse"
                  (clicked)="close()"></dds-button>
    </div>

    <!-- <hr class="line1"> -->
     <br>

    <div class="dds-modal__body bodyMargin">
        <div class="txtCheckboxWhy">{{lblGoodResponse}}</div>
        
            <form>
                <div class="checkbox-container">
                  <input type="checkbox" id="checkbox1" [(ngModel)]="checkboxState1" [ngModelOptions]="{standalone: true}" (change)="stateChanged(checkboxState1, 'AI hallucination: false information was provided')">
                  <label for="checkbox1">{{lblCheckbox1}}</label>
                </div>
                
                <div class="checkbox-container">
                  <input type="checkbox" id="checkbox2" [(ngModel)]="checkboxState2" [ngModelOptions]="{standalone: true}" (change)="stateChanged(checkboxState2, 'AI did not understand the provided context correctly')">
                  <label for="checkbox2">{{lblCheckbox2}}</label>
                </div>
                
                <div class="checkbox-container">
                  <input type="checkbox" id="checkbox3" [(ngModel)]="checkboxState3" [ngModelOptions]="{standalone: true}" (change)="stateChanged(checkboxState3, 'AI deviated from my instructions')">
                  <label for="checkbox3">{{lblCheckbox3}}</label>
                </div>
                
                <div class="checkbox-container">
                  <input type="checkbox" id="checkbox4" [(ngModel)]="checkboxState4" [ngModelOptions]="{standalone: true}" (change)="stateChanged(checkboxState4, 'AI response too generic / irrelevant to the query')">
                  <label for="checkbox4">{{lblCheckbox4}}</label>
                </div>
                
                <div class="checkbox-container">
                  <input type="checkbox" id="checkbox5" [(ngModel)]="checkboxState5" [ngModelOptions]="{standalone: true}" (change)="stateChanged(checkboxState5, 'Other (please specify in the comment below)')">
                  <label for="checkbox5">{{lblCheckbox5}}</label>
                </div>
              </form>
              
    

        <div>
        <label class="txtAdditionalComment">{{lblAdditionalComment}}</label>
       
        <div>
            <textarea class="feedbackBody" [(ngModel)]='txtFeedback' (input)="updateCharacterCount()"></textarea>
            <div class="charCount">{{txtFeedback.length}}/1000</div>
</div>
    </div>


</div>

    <div class="dds-modal__footer footerMargin">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            
                <div class="ext-center">
                    <button class="btnSubmit" (click)="Submit()">{{lblSubmit}}</button>
    </div>
        </div>
    </div>
 
</div>

</div>