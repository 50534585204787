import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { FlowOption } from 'src/app/model/agent.model';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from 'src/app/Services/LoadingService';
import { AppComponentService } from 'src/app/Services/appcomponentservice';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';


@Component({
  selector: 'app-deloitte-voice-flow',
  templateUrl: './deloitte-voice-flow.component.html',
  styleUrl: './deloitte-voice-flow.component.css'
})
export class DeloitteVoiceFlowComponent implements OnInit {
  
  private destroy = new Subject<void>(); 
  language: string = 'en'
  lblNameGreeting: string;
  lblHelpGreeting: string;  
  lblDescription: string;
  flowOptions: FlowOption[] = [
    {
      title: {
        en: 'Create audience-focused versions',
        fr: 'Créer des versions axées sur le public',
        es: 'Crear versiones centradas en la audiencia',
      },
      description: {
        en: 'Generate high-quality content based on your input, ensuring adherence to Deloitte\'s content style guide.',
        fr: 'Générez du contenu de haute qualité en fonction de votre saisie, en veillant à respecter le guide stylistique de Deloitte.',
        es:"Generar contenido de alta calidad basado en tu entrada, asegurando la adherencia a la guía de estilo de contenido de Deloitte."
      },
      type: 'create',
      disabled: false
    },
    {
      title: {
        en: 'Revise drafted content',
        fr: 'Réviser le contenu rédigé',
        es:"Revisar contenido redactado",
      },
      description: {
        en: "I'm your content curator, equipped with the latest company policies and style guides to help you refine existing content.",
        fr: "Je suis votre curateur de contenu, équipé des dernières politiques et guides stylistiques de l'entreprise pour vous aider à affiner le contenu existant.",
        es:"Soy tu curador de contenido, equipado con las últimas políticas de la empresa y guías de estilo para ayudarte a refinar el contenido existente."
      },
      type: 'revise',
      disabled: false
    }
  ];
  displayName: string;

  constructor(private router: Router,
    private i18n: I18nService,
    public loadingService: LoadingService,
    public appComponentService: AppComponentService, 
    private https: HttpClient) {}

  
  ngOnInit(): void {
    this.manageLocale();
    this.getProfile();
    this.loadingService.setLoadingState(false);
    this.appComponentService.enableNewTaskButton();
    this.appComponentService.setIsNewChatVisible(false); 
  }



  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.language = 'fr'
            this.lblNameGreeting = "Bonjour";
            this.lblDescription = "C'est parti ! Veuillez choisir le type de tâche sur laquelle vous souhaitez travailler…"
            break;
          case "ES":
              this.language = 'es'
              this.lblNameGreeting = "Hola";
              this.lblDescription = "¡Empecemos! Por favor, elige el tipo de tarea en la que te gustaría trabajar…"
              break;
          default:
            this.language = 'en'
            this.lblNameGreeting = "Hi";
            this.lblDescription = "Let's get started! Please choose the type of task you'd like to work on..."
            break;
        }
      });
  }  

  selectFlow(option: FlowOption) {
    if(!option.disabled){
      this.router.navigate([`/deloitte-voice/${option.type}`]);
    }
  }

  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.displayName = profile.givenName;
      })
  }
}
