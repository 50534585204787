import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { protectedResources } from '../../auth-config';
import { environment } from '../environments/environment';
 const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  

  constructor(private httpClient: HttpClient) { }
  apiurl = protectedResources.apiDBot.endpoint;
 
  submitFeedbackData(data:any){
    return this.httpClient.post<any>(environment.feedbackURL,data);
   }

   submitDocFeedbackData(data:any){
    return this.httpClient.post<any>(environment.docfeedbackURL,data);
   }

   submitBotFeedbackData(data:any){
    return this.httpClient.post<any>(this.apiurl+"Bot/SubmitBotFeedBack",data)
   }
}
