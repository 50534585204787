<div class="WhatsNewModel" [ngClass]="{'middleWhatsNewModelPopup': appcomponentservice.isPinChecked}">
  <div class="dds-modal-overlay" (click)="close()"></div>

  <div ddsResize class="dds-modal">
    <div class="dds-modal__header headerMargin">
      <img class="supportAgentImage" src="../assets/images/whatsnewicon.png" />
      <span class="dds-modal__title" class="txtFeedback">{{currentTranslation?.whatsNew}}</span>
      
      <dds-button size="sm"
                icon="dds-icon_close"
                kind="silent"
                theme="dark"
                ariaLabel="Close modal"
                class="dds-modal__close ddsBtnSelf"
                (clicked)="close()"></dds-button>
    </div>

    <hr class="line1">

    <div class="dds-modal__body bodyMargin">
      <div *ngIf="currentTranslation">
        <p class="txtCheckboxWhy">{{currentTranslation.releaseNote}}</p>
        <p></p>

        <ng-container *ngFor="let section of currentTranslation.sections">
          <p class="txtCheckboxWhy">{{section.title}}</p>
          <ul class="txtBulletPoints">
            <li *ngFor="let point of section.points">
              {{point}}
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</div>