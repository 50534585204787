<div id="homeContent">
    <div class="content-container" #chatContainer (scroll)="onScroll($event)">
       <div class="content-wrapper centered-text" [hidden]="!isDefaultText">
          <div class="txtHiNameStyle">
             <img src="../assets/images/rocket.png" />
             <span class="txtHiNameStyle2"> {{lblNameGreeting}}, {{displayName}}!</span>
          </div>
          <div class="txtPart2Style">
             {{lblHelpGreeting}}
          </div>
          <div class="txtPart345">
             <span class="txtPart4Style">
             {{lblGenDIntro2}}
             </span>
             <a class="txtPart5Style" href="{{KB_URL}}" target="_blank">
              {{lblIndexLastRefreshDate}}
             </a>
          </div>
          <br/>
         </div>
       <div class="dynamic-content" id="dynamicContent" [hidden]="isDefaultText" >
          <ng-container *ngFor="let message of messages$ | async let i = index" >
             <div class="content-with-icon userPrompt" *ngIf="message.user === 'user'">
                <i class="fa fa-user-circle imgDynamicContent1" aria-hidden="true"></i>
                <div class="message">
                   {{ message.text }}
                </div>
                <ng-container *ngIf="setUserMessage(message.text)" ></ng-container>
             </div>
             <div class="bot-content-with-icon botResponse" *ngIf="message.user === 'bot'">
                <i class="imgDynamicContent2Background"><img class="imgDynamicContent2 " src="../assets/images/sparkles.png" /></i>
                <div class="botmessage">
                   <markdown>{{ message.text }}</markdown>
                   <div class="references-section" *ngIf="message.references?.length > 0">
                     <p><strong>{{lblReferences}}</strong></p>
                     <div class="reference-links">
                       <ng-container *ngFor="let reference of message.references">
                         <a href="#" 
                            class="reference-link"
                            (click)="$event.preventDefault(); openFilePreview(reference)">
                           {{ reference.displayName }}
                         </a>
                         <br>
                       </ng-container>
                     </div>
                   </div>
                </div>
                <div class="thumbs-icons" *ngIf="i != messages.value.length -1|| !isStreaming">
                   <img *ngIf="!this.dislikedIndices.has(i) && !likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeFinal.png" (click)="openFeedbackLikeModel(i, message.text)"> 
                   <img *ngIf="!this.likedIndices.has(i) && !dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeFinal.png" (click)="openFeedbackDislikeModel(i, message.text)">                      
                   <img *ngIf="dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeIconGreen.png">
                   <img *ngIf="likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeIconGreen.png">
                   <img *ngIf="!isCopy || i != copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/clipboard.png" (click)="copyToClipboard(message.text, i)" />
                   <img *ngIf="isCopy && i === copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/check-circle.png" />
                </div>
             </div>
          </ng-container> </div>
    </div>
    <app-chat-window class="text-area"></app-chat-window>
</div>