<div class="file-upload-container" [ngClass]="{
  'has-files': this.files?.length > 0
}">
  <input 
        type="file" 
        #fileInput
        [multiple]="maxFiles > 1"
        [accept]="acceptedFileTypesString"
        (change)="onFileSelected($event)"
        style="display: none">
    <div 
      class="upload-zone" 
      (dragover)="onDragOver($event)" 
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="openFileInput()"
      [class.drag-over]="isDragOver"
      [class.has-error]="errors.length > 0"
      *ngIf="!files.length">
      
      <div class="upload-content">
        <img src="../../../assets/images/file-upload-icon.svg" alt="Upload Icon" class="upload-icon" />
        <span class="common-text">{{lblDragHere}}</span>
        <p class="common-text bold-text">{{uploadLimitMessage}}</p>
        <p *ngIf="additionalMessage" class="common-text bold-text">{{additionalMessage}}</p>
        <p class="common-text">{{lblSupportedFiles}}</p>
        </div>
    </div>
   

    <app-file-display  [files]="files" (fileRemoved)="removeFile($event)"> </app-file-display>

    <div class="bottom-container" *ngIf="errors.length || showUploadMoreButton">
      <div class="error-container" *ngIf="errors.length">
        <div class="error-message" *ngFor="let error of errors">
          {{ error[locale] }}
        </div>
      </div>

      <div class="button-container" *ngIf="showUploadMoreButton">
        <button 
          type="button"
          class="upload-button" 
          (click)="openFileInput()"
          [disabled]="isMaxFilesReached()">
          <img src="../../../assets/images/file-upload-icon.svg" alt="Upload Icon" class="button-icon" />
          {{lblUploadMore}}
        </button>
      </div>
    </div>
  </div>